// @flow
import React, { PureComponent } from "react";
import type { Device } from "@tvg/types/Device";
import tvgConf from "@tvg/conf";
import FundingMethodsHOC from "@tvg/funding-methods";
import perf from "../utils/perf";

type Props = {
  device: Device
};

class RgFundingMethods extends PureComponent<Props> {
  static defaultProps = {
    device: "mobile"
  };

  componentDidUpdate() {
    perf.measure("page_render", { page: "responsible_gaming" });
  }

  render() {
    return (
      <FundingMethodsHOC
        device={this.props.device}
        depositURL={tvgConf().buildUrl({
          app: "dma",
          path: "deposit",
          wrapper: true,
          callback: true,
          params: { fullPage: true, more: true }
        })}
      />
    );
  }
}

export default RgFundingMethods;
