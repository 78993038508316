// @flow
import React, { Fragment, PureComponent } from "react";
// $FlowFixMe
import { Route, Routes, type Location } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import type { RouterHistory } from "react-router";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { attempt, get, isEmpty, noop } from "lodash";
import tvgConf from "@tvg/conf";
import LoginService from "@tvg/login-service";
import ResponsibleGamingModal from "@tvg/responsible-gaming-modal";
import type {
  TvgConf,
  Feature,
  FeatureToggles,
  MapFeatureToggles,
  Messages
} from "@tvg/conf/src/types";

import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import type { NullaryFn } from "@tvg/types/Functional";
import {
  failFeaturesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  successMessagesRequest
} from "../actions/capi";
import {
  getUserData,
  setUserAccountNumber,
  setUserLoggedState
} from "../actions/user";
import type { UserData, UserActions } from "../actions/user";
import MicroApp from "./components/MicroApp";
import Header from "./components/webview-header";
import RgDepositLimits from "./components/rgDepositLimitsPage";
import RgFundingMethods from "./components/rgFundingMethodsPage";
import RGTimeout from "./components/rgTimeoutPage";
import isRn, { isRnTablet } from "./utils/isRn";

type Props = {
  userData: UserData,
  isLogged: boolean,
  dispatch: Dispatch<*>,
  features: Feature[],
  featureToggles: MapFeatureToggles,
  globalTVGContactSupportLink: string,
  messages: Messages,
  location: Location,
  history: RouterHistory,
  isBeta: boolean
};

// $FlowFixMe
export class Main extends PureComponent<Props, *> {
  constructor(props: Props) {
    super(props);
    if (typeof window !== "undefined" && isRn) {
      LoginService.validateSession().then(
        (response: { success: { data: Object } }) => {
          if (response.success) {
            this.props.dispatch(getUserData(response.success.data));
            this.props.dispatch(setUserLoggedState(true));
          } else {
            this.props.dispatch(setUserLoggedState(false));
          }
        }
      );
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (!isEmpty(this.props.features)) {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        this.props.features.forEach((toggle: Feature) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            enabled = featureOverrides[toggle.name].enabled;
          }

          featureToggles[toggle.name] = enabled;
        });

        if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
          // $FlowFixMe
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      } else {
        this.getFeatures();
      }

      this.getMessages();

      // tries to get the user id (accountNumber) from localStorage and dispatch it to user data
      // this is useful because every component initialized by tvg-mobile will know if the user is logged, plus it's
      // account number beforehand
      attempt(() => {
        if (
          typeof window !== "undefined" &&
          window.sessionStorage.getItem("userId")
        ) {
          this.props.dispatch(
            setUserAccountNumber(window.sessionStorage.getItem("userId"))
          );
        }
      });

      mediatorClassic.subscribe("UPDATE_ROUTER", () => {
        const pathWithParameters = window.location.href.replace(
          window.location.origin,
          ""
        );
        if (this.props.location.pathname !== pathWithParameters) {
          this.props.history.replace(pathWithParameters);
        }
      });

      mediator.base.subscribe("TVG4_NAVIGATION", (data) => {
        mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
      });

      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        (data: UserData) => {
          this.props.dispatch(getUserData(data));
        }
      );

      mediator.base.subscribe("TVG_LOGIN:DO_LOGOUT", (data) => {
        mediatorClassic.dispatch("TVG_LOGIN:DO_LOGOUT", data.payload);
      });

      mediator.base.subscribe("GET_RG_MODAL_CALLBACK", (data) => {
        this.rgModalCallback = get(data, "payload.callback", noop);
      });
      mediator.base.subscribe("GET_RG_MODAL_CALLBACK_SECONDARY", (data) => {
        this.rgModalCallbackSecondary = get(data, "payload.callback", noop);
      });
      mediator.base.subscribe("RG_MODAL_SUBMIT", () => {
        if (typeof this.rgModalCallback === "function") {
          this.rgModalCallback();
        }
        this.rgModalCallback = noop;
      });
      mediator.base.subscribe("RG_MODAL_CANCEL", () => {
        if (typeof this.rgModalCallbackSecondary === "function") {
          this.rgModalCallbackSecondary();
        }
        this.rgModalCallbackSecondary = noop;
      });

      mediator.base.subscribe("RG_MODAL_CLEAR_CALLBACKS", () => {
        this.rgModalCallback = noop;
        this.rgModalCallbackSecondary = noop;
      });

      if (
        ["iosnative", "androidnative"].includes(tvgConf().product) &&
        typeof window !== "undefined"
      ) {
        // this subscribe exists in MEP but doesn't exists in RN so we are using it
        // to catch all the requests that need to open the login wall
        mediator.base.subscribe("OPEN_LOGIN", (data) => {
          window.nativeBridge("OPEN_LOGIN_MODAL_RG", "", (cbData) => {
            const tvg3token = get(cbData, "tvg3Token", null);
            const useCallback = get(cbData, "useCallback", true);

            if (typeof window !== "undefined" && tvg3token && document) {
              LoginService.validateSession().then(
                (response: { success: { data: Object } }) => {
                  if (response.success) {
                    this.props.dispatch(getUserData(response.success.data));
                    this.props.dispatch(setUserLoggedState(true));
                    // there are cases where we don't want to call the callback
                    // passed to the window (ex. dma)
                    if (useCallback) {
                      data.payload.callback(
                        { error: "none" },
                        {
                          status: "success",
                          data: {
                            userDetails: get(response, "success.data.user", {})
                          }
                        }
                      );
                    }
                  } else {
                    this.props.dispatch(setUserLoggedState(false));
                  }
                }
              );
            }
          });
        });
      }
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.location.pathname !== this.props.location.pathname && isRn) {
      window.scrollTo(0, 0);
    }
  }

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features: Feature) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getCostumerSupportURL = () => {
    if (
      tvgConf().product !== "iosnative" &&
      tvgConf().product !== "androidnative"
    ) {
      return {
        customerServicesUrl: this.props.globalTVGContactSupportLink
      };
    }
    if (typeof window !== "undefined") {
      return {
        customerServicesUrl: this.props.globalTVGContactSupportLink
      };
    }
    return {
      customerServicesUrl: this.props.globalTVGContactSupportLink
    };
  };

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response: FeatureToggles | false) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle: Feature) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          // $FlowFixMe
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err: Error) => this.props.dispatch(failFeaturesRequest(err)));

  getMessages = () => {
    const messageNamespaces = [
      "ResponsibleGaming",
      "InformationalPages",
      "Homepage"
    ];

    return this.tvg
      .getMessages(messageNamespaces)
      .then((response) => this.props.dispatch(successMessagesRequest(response)))
      .catch((err: Error) => this.props.dispatch(failMessagesRequest(err)));
  };

  enableMicroApp = () => true;

  tvg: TvgConf = tvgConf();

  rgModalCallback: NullaryFn<mixed>;

  rgModalCallbackSecondary: NullaryFn<mixed>;

  device = get(this.tvg, "device");

  product = get(this.tvg, "product");

  render() {
    const path = get(this.props, "location.pathname");
    const parameters = get(this.props, "location.search");
    const currentPath = `${path}${parameters}`;
    const RnDevice = isRnTablet ? "tablet" : "mobile";

    return (
      <Fragment>
        {isRn && (
          <Fragment>
            <Header history={this.props.history} />
            {/* Don't ask how we got here */}
            <div style={{ display: "none" }} onTouchStart />
          </Fragment>
        )}
        <Routes>
          {/* $FlowFixMe */}
          <Route
            path="/responsible-gaming"
            element={
              <MicroApp
                history={this.props.history}
                isEnabled={this.enableMicroApp()}
                isLogged={this.props.isLogged}
                hasRgLoggedOutToggle={get(this.props, "hasRgLoggedOutToggle")}
                device={this.device}
                isRn={isRn}
                product={this.product}
                currentPath={currentPath}
                dispatch={this.props.dispatch}
              />
            }
          />
          {isRn && (
            <Fragment>
              {/* $FlowFixMe */}
              <Route
                path="/responsible-gaming/deposit-limits"
                element={
                  <RgDepositLimits device={isRn ? RnDevice : this.device} />
                }
              />
              {/* $FlowFixMe */}
              <Route
                path="/responsible-gaming/funding-controls"
                element={
                  <RgFundingMethods device={isRn ? RnDevice : this.device} />
                }
              />
              {/* $FlowFixMe */}
              <Route
                path="/responsible-gaming/self-exclude"
                element={
                  <RGTimeout
                    currentPath={currentPath}
                    device={isRn ? RnDevice : this.device}
                  />
                }
              />
              {/* $FlowFixMe */}
              <Route
                path="/responsible-gaming/timeout"
                element={
                  <RGTimeout
                    currentPath={currentPath}
                    device={isRn ? RnDevice : this.device}
                  />
                }
              />
              {/* $FlowFixMe */}
              <Route
                path="/responsible-gaming/suspend-account"
                element={
                  <RGTimeout
                    currentPath={currentPath}
                    device={isRn ? RnDevice : this.device}
                  />
                }
              />
            </Fragment>
          )}
        </Routes>
        <ResponsibleGamingModal
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          device={this.device}
          product={this.product}
          {...this.getCostumerSupportURL()}
        />
      </Fragment>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({
      isLogged: get(store, "userData.logged"),
      hasRgLoggedOutToggle: get(
        store,
        "capi.featureToggles.hasRgLoggedOut",
        undefined
      ),
      userData: get(store, "userData"),
      featureToggles: get(store, "capi.featureToggles"),
      features: get(store, "capi.features"),
      messages: get(store, "capi.messages"),
      globalTVGContactSupportLink:
        (store,
        "capi.messages.globalTVGContactSupportLink",
        tvgConf().getExternalDomain().contactSupport)
    }),
    (dispatch: Dispatch<UserActions>) => ({ dispatch })
  )(Main)
);
