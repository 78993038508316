// @flow
import type { AppActions } from "../../actions/app";

export type State = {
  exampleList: string[],
  example2List: string[]
};

export const initialState = {
  exampleList: [],
  example2List: []
};

const appReducer = (state: State = initialState, action: AppActions) => {
  switch (action.type) {
    case "EXAMPLE": {
      return { ...state, exampleList: action.payload };
    }
    case "EXAMPLE2": {
      return { ...state, example2List: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
