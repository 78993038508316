// @flow

import React, { Fragment } from "react";
import { noop } from "lodash";
import type { UnaryFn } from "@tvg/types/Functional";
import {
  GroupContainer,
  GroupTitle,
  OptionsWrapper,
  OptionContainer,
  OptionSelectionMark,
  OptionTitle
} from "./styled-components";

export type Option = {
  id: string,
  title: string,
  description?: string
};

export type OptionGroup = {
  groupId: number,
  groupTitle?: string,
  options: Option[]
};

type Props = {
  qaLabel: string,
  listOptionGroup: OptionGroup[],
  optionSelected: string | null,
  optionSelectedHandler: UnaryFn<string, mixed>
};

export default function ListOptionGroup({
  qaLabel,
  listOptionGroup,
  optionSelected,
  optionSelectedHandler
}: Props) {
  return (
    <Fragment>
      {listOptionGroup.map((optionGroup) => (
        <GroupContainer
          key={`group-${optionGroup.groupId}`}
          data-qa-label={`${qaLabel}-${optionGroup.groupId}`}
        >
          {optionGroup.groupTitle && (
            <GroupTitle
              data-qa-label={`${qaLabel}-${optionGroup.groupId}-title`}
            >
              {optionGroup.groupTitle}
            </GroupTitle>
          )}
          <OptionsWrapper
            data-qa-label={`${qaLabel}-${optionGroup.groupId}-options`}
          >
            {optionGroup.options.map((option) => (
              <OptionContainer
                key={option.id}
                onClick={() => optionSelectedHandler(option.id)}
                data-qa-label={`${qaLabel}-${optionGroup.groupId}-options-${option.id}`}
              >
                {option.id === optionSelected && <OptionSelectionMark />}
                <OptionTitle isSelected={option.id === optionSelected}>
                  {option.title}
                </OptionTitle>
              </OptionContainer>
            ))}
          </OptionsWrapper>
        </GroupContainer>
      ))}
    </Fragment>
  );
}

ListOptionGroup.defaultProps = {
  qaLabel: "listOptionGroup",
  listOptionGroup: [],
  optionSelected: null,
  optionSelectedHandler: noop
};
