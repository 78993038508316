// @flow

import React, { PureComponent } from "react";
import { noop } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { errorOutline } from "../../_static/Icons/icons";
import type { InputTypeEnum } from "../../_atom/Input/types";
import {
  InputWrapper,
  InputField,
  InputDollarSign,
  InputIconWrapper,
  InputLabel,
  InputContainer,
  ErrorMessage
} from "./styled-components";

export type Props = {
  /**
   * Type of input
   */
  type: InputTypeEnum,
  /**
   * Input pattern
   */
  pattern?: string,
  /**
   * Is input valid
   */
  isValid: boolean,
  /**
   * change handler
   */
  onChangeHandler: UnaryFn<Event, void>,
  /**
   * onBlur handler
   */
  onFocusHandler: NullaryFn<void>,
  /**
   * onBlur handler
   */
  onBlurHandler: NullaryFn<void>,
  /**
   * callback for pressing the GO button
   */
  onKeyPressHandler: UnaryFn<SyntheticKeyboardEvent<*>, mixed>,
  /**
   * callback for pressing clean button
   */
  onCleanInputHandler?: NullaryFn<void> | null,
  /**
   * displayed text
   */
  value: string,
  /**
   * Value max length
   */
  maxLength: ?number,
  /**
   * placeholder to show
   */
  placeholder: string,
  /**
   * placeholder is more opacity
   */
  opacityPlaceholder: boolean,
  /**
   * Show dollar sign before input value
   */
  showDollarSign: boolean,
  /**
   * label to show above the input
   */
  label: string,
  /**
   * Error message
   */
  errorMsg: string,
  /**
   * data-qa-label
   */
  qaLabel: string,
  /**
   * Input size
   */
  size: string
};

export default class GenericInput extends PureComponent<Props> {
  static defaultProps = {
    type: "text",
    pattern: "*",
    isValid: true,
    onChangeHandler: noop,
    onFocusHandler: noop,
    onBlurHandler: noop,
    onKeyPressHandler: noop,
    onCleanInputHandler: null,
    value: "",
    maxLength: 10,
    placeholder: "",
    isLighterPlaceholder: false,
    opacityPlaceholder: false,
    showDollarSign: true,
    label: "",
    errorMsg: "",
    qaLabel: "",
    size: "normal"
  };

  renderLabel() {
    return (
      <InputLabel
        htmlFor={`${this.props.type}Login`}
        data-qa-label={`${this.props.qaLabel}Label`}
      >
        {this.props.label}
      </InputLabel>
    );
  }

  renderInput() {
    return (
      <InputWrapper
        size={this.props.size}
        isValid={this.props.isValid}
        opacityPlaceholder={this.props.opacityPlaceholder}
      >
        {this.props.showDollarSign && <InputDollarSign>$</InputDollarSign>}
        <InputField
          type={this.props.type}
          value={this.props.value}
          pattern={this.props.pattern}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          opacityPlaceholder={this.props.opacityPlaceholder}
          onChange={this.props.onChangeHandler}
          onFocus={this.props.onFocusHandler}
          onBlur={this.props.onBlurHandler}
          onKeyPress={this.props.onKeyPressHandler}
          isValid={this.props.isValid}
          showDollarSign={this.props.showDollarSign}
          key={this.props.qaLabel}
          id={`${this.props.type}Login`}
          data-qa-label={`${this.props.qaLabel}Input`}
        />
        {this.props.value &&
          this.props.value !== "0" &&
          this.props.onCleanInputHandler && (
            <InputIconWrapper onClick={this.props.onCleanInputHandler}>
              <Icon
                icon={errorOutline}
                color={buildColor("grey", "500")}
                size={16}
              />
            </InputIconWrapper>
          )}
      </InputWrapper>
    );
  }

  renderErrorMsg() {
    return (
      !this.props.isValid && (
        <ErrorMessage data-qa-label={`${this.props.qaLabel}ErrMsg`}>
          {this.props.errorMsg}
        </ErrorMessage>
      )
    );
  }

  render() {
    return (
      <InputContainer data-qa-label={`${this.props.qaLabel}Section`}>
        {this.renderLabel()}
        {this.renderInput()}
        {this.renderErrorMsg()}
      </InputContainer>
    );
  }
}
