// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, defaultSize } from "../../_static/Typography";

export default styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${buildColor("blue", "000")};
  flex-grow: 1;
  overflow: auto;
  ${({ isTablet }) =>
    isTablet &&
    css`
      width: 100%;
    `}

  & * a {
    text-decoration: none;
  }
`;

export const ContainerDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4px 12px 12px;
  background-color: ${buildColor("blue", "700")};
`;

export const Description = styled.p`
  width: 100%;
  max-width: 656px;
  color: ${buildColor("blue", "200")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  white-space: pre-line;
`;

export const ContainerListItems = styled.div`
  ${({ device }) =>
    device === "mobile" ? "margin-bottom: 8px;" : "margin-top: 12px;"}
  width: 100%;
  max-width: 656px;
`;

export const ContainerFooter = styled.div`
  margin: 16px 12px;
  max-width: 656px;
  color: ${buildColor("grey", "700")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  flex-grow: 1;
  overflow: auto;

  & * a {
    vertical-align: baseline;
  }
`;

export const FooterTitle = styled.h3`
  margin-bottom: 4px;
  font-size: 16px;
  font-family: ${fontBold};
  font-weight: bold;
  letter-spacing: 0;
`;
