// @flow

import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, defaultSize, fontBold } from "../../_static/Typography";

export const MoreInfo = styled.div`
  margin: 16px 0;
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-weight: normal;
  font-family: ${fontNormal};
  letter-spacing: 0;
`;

export const Title = styled.h4`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: ${defaultSize};
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 3px;
  margin-bottom: 12px;
`;

export const ItemSelectionWrapper = styled.div`
  .rg-justify-end {
    justify-content: end;
  }
`;
