import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, defaultSize } from "../../_static/Typography";

export const DepositLimitWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ device }) =>
    device === "desktop"
      ? css`
          border: 1px solid ${buildColor("blue", "100")};
          box-shadow: 0 4px 8px 0 rgba(147, 175, 205, 0.03),
            0 1px 3px 0 rgba(99, 119, 140, 0.04);
          border-radius: 2px;
        `
      : css`
          height: 100vh;
          background: ${buildColor("blue", "000")};
        `}

  ${({ device }) =>
    device === "mobile" &&
    css`
      border-radius: 0;
      box-shadow: 0 1px00 rgb(214, 227, 240);
    `}
`;

export const DepositLimitTitle = styled.h2`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 16px 16px 0;
`;

export const DepositLimitSelectionWrapper = styled.div`
  width: 100%;
  ${({ device }) =>
    device === "desktop"
      ? `border-top: 1px solid ${buildColor("blue", "100")};`
      : `border: 1px solid ${buildColor("blue", "100")};
        margin-bottom: 10px;
      `}

  background-color: ${buildColor("white", "100")};
  padding-bottom: 16px;
  ${({ device }) =>
    device === "tablet" &&
    `margin-top: 10px; 
    max-width: 744px;`}
`;

export const MoreInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${buildColor("white", "100")};
`;

export const MoreInfo = styled.p`
  width: 100%;
  ${({ device }) =>
    device === "tablet" &&
    css`
      max-width: 744px;
      margin-left: 25px;
    `}
  ${({ device }) =>
    device === "desktop" ? "padding: 8px 16px 12px;" : "padding: 0 12px 10px;"}
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  white-space: pre-line;
`;

export const Details = styled.div`
  ${({ device }) =>
    device === "desktop" ? "margin: 6px 16px 0;" : "margin: 6px 12px 0;"}
`;

export const InputSeparator = styled.div`
  height: 1px;
  background-color: ${buildColor("blue", "100")};
  margin: 16px -12px 0 0;
`;

export const ErrorWrapper = styled.div`
  padding: 40px 0;
  background: ${buildColor("white", "100")};
`;
