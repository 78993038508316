// @flow
import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const Wrapper = styled.div`
  background-color: ${buildColor("blue", "000")};
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 221px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 20px;
  ${({ isRN }) =>
    isRN &&
    css`
      flex-direction: column;
    `}
`;

export const Header = styled.h1`
  font-family: Helvetica, Verdana, Tahoma, sans-serif;
  font-size: 50px;
`;
