// @flow
import { get, noop } from "lodash";
import uwtService from "@tvg/api/uwt";
import type { UnaryFn } from "@tvg/types/Functional";
import RG_OPTIONS, {
  type uwtPaymentOption,
  type Method,
  type PaymentOptionsEnum
} from "@tvg/types/ResponsibleGaming";
import mediator from "@tvg/mediator";

export const MODULE_NAME = RG_OPTIONS.FUNDING_CONTROLS;

export const MODAL_STATUS = {
  CLOSED: "",
  CONFIRM: "CONFIRM",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export const blockGtmModalOpen = (eventLabel: string) => {
  mediator.base.dispatch({
    type: "RG_BLOCK_MODAL_OPEN",
    payload: {
      gaEventLabel: eventLabel
    }
  });
};

export const blockGtmHandle = (eventLabel: string) => {
  mediator.base.dispatch({
    type: "RG_MODAL_CLICK_HANDLE",
    payload: {
      gaEventLabel: eventLabel,
      module: MODULE_NAME
    }
  });
};

/*
  VIWD - Visa Withdrawals should not be considerened for Funding Controls
*/
const parsePaymentMethodData = (method: uwtPaymentOption) => ({
  isBlocked:
    get(method, "responsibleIsBlocked", false) ||
    !get(method, "isDepositAvailable", true),
  name: get(method, "paymentType", ""),
  unblockDate: get(method, "responsibleUnblockDate")
});

export const invalidFundingMethods: string[] = [
  "IBC",
  "DEBS",
  "CCS",
  "CASH",
  "APPL",
  "VIWD",
  "CASH",
  "OTR",
  "WTRN",
  "CHK"
];

export const validFundingMethod = ({ name }: Method): boolean =>
  !invalidFundingMethods.includes(name);

const fetchPaymentMethods = (
  accountNumber: string,
  isLogged: boolean,
  setActiveMethods: UnaryFn<Method[], void>,
  setBlockedMethods: UnaryFn<Method[], void>,
  setError: UnaryFn<boolean, void> = noop,
  isMZMToggleOn: boolean = false
) => {
  if (accountNumber && isLogged) {
    return uwtService
      .getPaymentOptions(accountNumber)
      .then((res: { data: uwtPaymentOption[] }) => {
        let parsedMethod: ?Method = null;
        const parsedActiveMethods: Method[] = [];
        const parsedBlockedMethods: Method[] = [];
        get(res, "data", []).forEach((method: uwtPaymentOption) => {
          if (!isMZMToggleOn && method.paymentType === "MZM") {
            return;
          }
          parsedMethod = parsePaymentMethodData(method);
          if (parsedMethod && parsedMethod.isBlocked)
            parsedBlockedMethods.push(parsedMethod);
          else if (parsedMethod) parsedActiveMethods.push(parsedMethod);
        });
        setActiveMethods(parsedActiveMethods.filter(validFundingMethod));
        setBlockedMethods(parsedBlockedMethods.filter(validFundingMethod));
      })
      .catch(() => {
        setError(true);
      });
  }

  setActiveMethods([]);
  setBlockedMethods([]);

  return Promise.resolve({});
};

const blockPaymentMethod = (
  accountNumber: string,
  paymentType: PaymentOptionsEnum
) => uwtService.blockPaymentMethod(accountNumber, paymentType);

export default {
  fetchPaymentMethods,
  blockPaymentMethod
};
