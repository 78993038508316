// @flow
// $FlowFixMe
import React, { Fragment, useMemo } from "react";
import { noop } from "lodash";
import type { UnaryFn } from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";

import ItemGroup from "../../_molecule/RGItemGroup";
import { MoreInfo, Title, ItemSelectionWrapper } from "./styled-components";

type Props = {
  indexSelected: number,
  indexSelectedUpdate: UnaryFn<number, void>,
  indexesApplied: number[],
  itemSelectionTitle: string,
  itemSelectionDescription: string,
  items: ListItemType[],
  isSimplified: boolean
};

const renderItemSelection = ({
  itemSelectionTitle,
  itemSelectionDescription,
  indexSelected,
  indexSelectedUpdate,
  indexesApplied,
  items
}: Props) => (
  <Fragment>
    <Title data-qa-label="rg-item-selection-title">{itemSelectionTitle}</Title>
    <MoreInfo data-qa-label="rg-item-selection-more-info">
      {itemSelectionDescription}
    </MoreInfo>
    <ItemGroup
      indexSelected={indexSelected}
      indexSelectedUpdate={indexSelectedUpdate}
      indexesApplied={indexesApplied}
      items={items}
    />
  </Fragment>
);

const renderItemSelectionSimplified = ({
  indexSelected,
  indexSelectedUpdate,
  indexesApplied,
  items,
  isSimplified
}: Props) => (
  <ItemSelectionWrapper data-qa-label="rg-item-item-selection">
    <ItemGroup
      indexSelected={indexSelected}
      indexSelectedUpdate={indexSelectedUpdate}
      indexesApplied={indexesApplied}
      items={items}
      isSimplified={isSimplified}
      className="rg-justify-end"
    />
  </ItemSelectionWrapper>
);

const ItemSelection = (props: Props) => {
  const {
    indexSelected,
    indexSelectedUpdate,
    itemSelectionDescription,
    itemSelectionTitle,
    items,
    isSimplified,
    indexesApplied
  } = props;

  return useMemo(
    () =>
      isSimplified
        ? renderItemSelectionSimplified(props)
        : renderItemSelection(props),
    [
      indexSelected,
      indexSelectedUpdate,
      itemSelectionTitle,
      itemSelectionDescription,
      isSimplified,
      JSON.stringify(items),
      JSON.stringify(indexesApplied)
    ]
  );
};

ItemSelection.defaultProps = {
  indexSelected: 0,
  indexSelectedUpdate: noop,
  indexesApplied: [],
  itemSelectionTitle: "",
  itemSelectionDescription: "",
  items: [],
  isSimplified: false
};

export default ItemSelection;
