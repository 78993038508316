// @flow
import React from "react";
import { noop } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import RG_OPTIONS from "@tvg/types/ResponsibleGaming";
import { TOKEN_DELIMITER } from "@tvg/utils/capiUtils";

import {
  Container,
  Status,
  StatusName,
  StatusValue,
  Actions,
  ContainerValue,
  StatusPrevious,
  ErrorMessage,
  FundingMessage,
  ExclusionMessage,
  TextHighlighted,
  TextNormal
} from "./styled-components";
import Button from "../../_atom/Buttons/button";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import Icon from "../../_static/Icons";
import { arrowForward } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import type {
  DepositLimitModal,
  FundingControlModal,
  ExclusionModal,
  ModalContent,
  Props,
  ModalBody
} from "./types";
import ProcessingOverlay from "../../_static/ProcessingOverlay";

const TIMEOUT_PERIOD_TOKEN = "timeoutPeriod";
const RETURN_DATE_TOKEN = "returnDate";

const renderDepositLimit = ({ depositLimitStatus }: DepositLimitModal) =>
  depositLimitStatus.map(({ name, value, previous }) => (
    <Status
      key={`status-${name}`}
      data-qa-label={`status-${name.toLowerCase().trim().replace(/ /g, "-")}`}
    >
      <StatusName data-qa-label="status-name">{name}</StatusName>
      <ContainerValue data-qa-label="container-values">
        {previous && (
          <StatusPrevious data-qa-label="status-previous-value">
            {previous}
            <Icon
              icon={arrowForward}
              size={12}
              color={buildColor("blue_accent", "500")}
              qaLabel="previous-value-icon"
            />
          </StatusPrevious>
        )}
        <StatusValue data-qa-label="status-value">{value}</StatusValue>
      </ContainerValue>
    </Status>
  ));

const renderFundingControl = ({ fundingMethod }: FundingControlModal) => (
  <FundingMessage data-qa-label="funding-method">
    {fundingMethod}
  </FundingMessage>
);

const renderExclusions = ({
  timeoutPeriod,
  returnDate,
  messages
}: ExclusionModal) =>
  messages.map((message, msgIdx) => (
    // eslint-disable-next-line react/no-array-index-key
    <ExclusionMessage key={`message-${msgIdx}`}>
      {message
        .split(new RegExp(TOKEN_DELIMITER))
        .filter(Boolean)
        .map((text, textIdx) => {
          if (text === TIMEOUT_PERIOD_TOKEN)
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TextHighlighted key={`timeout-${textIdx}`}>
                {timeoutPeriod}
              </TextHighlighted>
            );
          if (text === RETURN_DATE_TOKEN)
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TextHighlighted key={`returnDate-${textIdx}`}>
                {returnDate}
              </TextHighlighted>
            );
          return (
            // eslint-disable-next-line react/no-array-index-key
            <TextNormal data-qa-label="rg-label-text" key={`text-${textIdx}`}>
              {text}
            </TextNormal>
          );
        })}
    </ExclusionMessage>
  ));

const renderError = (error) => (
  <ErrorMessage data-qa-label="error-message">{error}</ErrorMessage>
);

const getContent = ({
  type,
  depositLimitStatus,
  error,
  fundingMethod,
  timeoutPeriod,
  returnDate,
  messages
}: ModalContent) => {
  switch (type) {
    case RG_OPTIONS.DEPOSIT_LIMIT:
      return renderDepositLimit({ depositLimitStatus });
    case RG_OPTIONS.FUNDING_CONTROLS:
      return renderFundingControl({ fundingMethod });
    case RG_OPTIONS.TIMEOUT:
    case RG_OPTIONS.SELF_EXCLUSION:
    case RG_OPTIONS.SUSPEND:
      return renderExclusions({ timeoutPeriod, returnDate, messages });
    default:
      return renderError(error);
  }
};

const getPrimaryButtonConfig = (
  callback: NullaryFn<void> | UnaryFn<any, void>,
  isFinished: boolean,
  url?: string = ""
) => {
  const baseConfig = {
    onClick: callback,
    type: isFinished ? "primary" : "bet",
    size: "big",
    qaLabel: "primary-button"
  };

  return url ? { url, ...baseConfig } : baseConfig;
};

const getSecondaryButtonConfig = (
  callback: NullaryFn<void>,
  hasError: boolean,
  url?: string = ""
) => {
  const baseConfig = {
    onClick: callback,
    type: hasError ? "tertiary" : "secondary",
    size: "big",
    qaLabel: "secondary-button"
  };

  return url ? { url, ...baseConfig } : baseConfig;
};

const renderProcessing = () => <ProcessingOverlay />;

const renderBody = ({
  isProcessing,
  hasError,
  type,
  depositLimitStatus,
  error,
  fundingMethod,
  timeoutPeriod,
  returnDate,
  messages
}: ModalBody) => {
  if (isProcessing) {
    return renderProcessing();
  }
  if (hasError) {
    return renderError(error);
  }

  return getContent({
    type,
    depositLimitStatus,
    error,
    fundingMethod,
    timeoutPeriod,
    returnDate,
    messages
  });
};

const ResponsibleGamingModal = ({
  type,
  primaryButton,
  primaryButtonText,
  primaryButtonUrl,
  secondaryButton,
  secondaryButtonText,
  secondaryButtonUrl,
  isFinished,
  depositLimitStatus,
  qaLabel,
  hasError,
  error,
  fundingMethod,
  timeoutPeriod,
  returnDate,
  messages,
  isProcessing
}: Props) => (
  <Container data-qa-label={`${qaLabel}-container`}>
    {renderBody({
      isProcessing,
      hasError,
      type,
      depositLimitStatus,
      error,
      fundingMethod,
      timeoutPeriod,
      returnDate,
      messages
    })}
    <Actions data-qa-label="status-actions">
      {primaryButtonUrl ? (
        <ButtonLink
          {...getPrimaryButtonConfig(
            primaryButton,
            isFinished,
            primaryButtonUrl
          )}
        >
          {primaryButtonText}
        </ButtonLink>
      ) : (
        <Button {...getPrimaryButtonConfig(primaryButton, isFinished)}>
          {primaryButtonText}
        </Button>
      )}
      {secondaryButtonUrl ? (
        <ButtonLink
          {...getSecondaryButtonConfig(
            secondaryButton,
            hasError,
            secondaryButtonUrl
          )}
        >
          {secondaryButtonText}
        </ButtonLink>
      ) : (
        <Button
          {...getSecondaryButtonConfig(secondaryButton, hasError)}
          {...(secondaryButtonText.toLowerCase() === "cancel" ||
          secondaryButtonText.toLowerCase().includes("return")
            ? { optionalData: "closeRGModal" }
            : {})}
        >
          {secondaryButtonText}
        </Button>
      )}
    </Actions>
  </Container>
);

ResponsibleGamingModal.defaultProps = {
  type: RG_OPTIONS.DEPOSIT_LIMIT,
  primaryButton: noop,
  primaryButtonText: "",
  primaryButtonUrl: "",
  secondaryButton: noop,
  secondaryButtonText: "",
  secondaryButtonUrl: "",
  isFinished: false,
  depositLimitStatus: [],
  qaLabel: "responsible-gaming-modal",
  hasError: false,
  error: "",
  fundingMethod: "",
  timeoutPeriod: "",
  returnDate: "",
  messages: [],
  isProcessing: false
};

export default ResponsibleGamingModal;
