// @flow
import React, { PureComponent } from "react";
import DepositLimitsComponent from "@tvg/deposit-limits";
import type { Device } from "@tvg/types/Device";

import perf from "../utils/perf";

type Props = {
  device: Device
};

class RgDepositLimits extends PureComponent<Props> {
  static defaultProps = {
    device: "mobile"
  };

  componentDidUpdate() {
    perf.measure("page_render", { page: "deposit_limits" });
  }

  render() {
    return (
      // $FlowFixMe
      <DepositLimitsComponent device={this.props.device} />
    );
  }
}

export default RgDepositLimits;
