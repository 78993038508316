// @flow
import styled from "styled-components";
import {
  defaultSize,
  fontNormal,
  fontMedium,
  fontBold
} from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  width: 100%;
  padding: 16px 12px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  background: ${buildColor("white", "100")};
`;

export const Status = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const StatusName = styled.p`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
`;

export const StatusValue = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  font-weight: 500;
  text-align: right;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 8px;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ContainerValue = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusPrevious = styled.span`
  display: flex;
  align-items: center;
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  font-weight: 500;

  > svg {
    margin: 0 4px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  margin-bottom: 8px;
`;

export const FundingMessage = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: ${defaultSize};
  font-weight: 500;
  width: 100%;
  margin: 12px 0;
`;

export const ExclusionMessage = styled.p`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  width: 100%;
`;

export const TextHighlighted = styled.span`
  font-family: ${fontBold};
`;

export const TextNormal = styled.span`
  font-family: ${fontNormal};
`;
