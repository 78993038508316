// @flow

// $FlowFixMe
import React, { Fragment, useMemo, useEffect } from "react";
import { get, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { UnaryFn } from "@tvg/types/Functional";
import Icon from "../../_static/Icons";
import {
  contests,
  refresh,
  arrowDownward,
  arrowForward
} from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Button from "../../_atom/Buttons/button";
import {
  PendingLimitsSeparator,
  Container,
  LimitDescription,
  IconWrapper,
  IconWrapperSlim,
  LimitsInfo,
  LimitValue,
  LimitStatus,
  LimitSeparator,
  LimitSeparatorLine,
  PendingLimitDateDescription,
  CurrentPendingContainer,
  PendingContainer
} from "./styled-components";

type LimitType = "current" | "pending";

type Limit = {
  value: string,
  pendingLimitDate?: string
};

type Props = {
  device: Device,
  isInputActive: boolean,
  toggleInputCallback: UnaryFn<boolean, void>,
  currentLimit: Limit,
  pendingLimit: Limit,
  currentLimitLabel: string,
  pendingLimitLabel: string,
  appliedLabel: string,
  editButtonText: string,
  cancelButtonText: string
};

const renderLimitBaseInfo = (
  limitValue: string,
  limitType: LimitType,
  limitLabel: string
) => {
  const isCurrentType = limitType === "current";
  return (
    <LimitDescription>
      <IconWrapper
        isCurrentType={isCurrentType}
        data-qa-label={`icon-wrapper-${limitType}`}
      >
        <Icon
          qaLabel={`${limitType}LimitIcon`}
          icon={isCurrentType ? contests : refresh}
          color={
            isCurrentType
              ? buildColor("grey", "600")
              : buildColor("orange", "500")
          }
          size={18}
        />
      </IconWrapper>
      <LimitsInfo>
        <LimitValue data-qa-label={`${limitType}Limit`}>
          {limitValue}
        </LimitValue>
        <LimitStatus data-qa-label={`${limitType}LimitStatus`}>
          {limitLabel}
        </LimitStatus>
      </LimitsInfo>
    </LimitDescription>
  );
};

const renderPendingLimitDescription = (
  pendingLimitDate: string,
  appliedLabel: string,
  rightAlignment?: boolean = true
) => (
  <PendingLimitDateDescription>
    <LimitValue
      data-qa-label="pendingLimitDate"
      rightAlignment={rightAlignment}
    >
      {pendingLimitDate}
    </LimitValue>
    <LimitStatus
      data-qa-label="pendingLimitStatus"
      rightAlignment={rightAlignment}
    >
      {appliedLabel}
    </LimitStatus>
  </PendingLimitDateDescription>
);

const renderEditCancelBtn = (
  editButtonText: string = "",
  cancelButtonText: string = "",
  isInputActive: boolean = false,
  toggleInputCallback: UnaryFn<boolean, void> = noop
) => (
  <Button
    qaLabel="editLimitButton"
    type="secondary"
    size="big"
    onClick={() => toggleInputCallback(!isInputActive)}
    className="editLimitBtn"
  >
    {isInputActive ? cancelButtonText : editButtonText}
  </Button>
);

const PendingLimits = (props: Props) => {
  useEffect(() => {
    if (get(props, "currentLimit.value", false))
      props.toggleInputCallback(false);
    else props.toggleInputCallback(true);
  }, [JSON.stringify(props.currentLimit)]);

  const currentLimitValue = get(props, "currentLimit.value");
  const { value: pendingLimitValue, pendingLimitDate } = get(
    props,
    "pendingLimit"
  );

  return useMemo(
    () =>
      currentLimitValue &&
      (props.device === "mobile" ? (
        <Fragment>
          <PendingLimitsSeparator />
          <Container>
            {renderLimitBaseInfo(
              currentLimitValue,
              "current",
              props.currentLimitLabel
            )}
            {renderEditCancelBtn(
              props.editButtonText,
              props.cancelButtonText,
              props.isInputActive,
              props.toggleInputCallback
            )}
          </Container>
          {pendingLimitValue && (
            <Fragment>
              <LimitSeparator data-qa-label="lineSeparator">
                <Icon
                  qaLabel="arrowDownIcon"
                  icon={arrowDownward}
                  color={buildColor("blue", "200")}
                  size={16}
                />
                <LimitSeparatorLine />
              </LimitSeparator>
              <Container>
                {renderLimitBaseInfo(
                  pendingLimitValue,
                  "pending",
                  props.pendingLimitLabel
                )}
                {renderPendingLimitDescription(
                  pendingLimitDate,
                  props.appliedLabel
                )}
              </Container>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <PendingLimitsSeparator />
          <Container>
            <CurrentPendingContainer>
              {renderLimitBaseInfo(
                currentLimitValue,
                "current",
                props.currentLimitLabel
              )}
              {get(props, "pendingLimit.value", false) && (
                <Fragment>
                  <IconWrapperSlim>
                    <Icon
                      qaLabel="arrowForwardIcon"
                      icon={arrowForward}
                      color={buildColor("blue", "200")}
                      size={16}
                    />
                  </IconWrapperSlim>
                  <PendingContainer>
                    {renderLimitBaseInfo(
                      pendingLimitValue,
                      "pending",
                      props.pendingLimitLabel
                    )}
                    {renderPendingLimitDescription(
                      pendingLimitDate,
                      props.appliedLabel,
                      false
                    )}
                  </PendingContainer>
                </Fragment>
              )}
            </CurrentPendingContainer>
            {renderEditCancelBtn(
              props.editButtonText,
              props.cancelButtonText,
              props.isInputActive,
              props.toggleInputCallback
            )}
          </Container>
        </Fragment>
      )),
    [
      props.device,
      JSON.stringify(props.currentLimit),
      JSON.stringify(props.pendingLimit),
      props.isInputActive
    ]
  );
};

PendingLimits.defaultProps = {
  device: "mobile",
  isInputActive: false,
  toggleInputCallback: noop,
  currentLimit: {
    value: ""
  },
  pendingLimit: {
    value: "",
    pendingLimitDate: ""
  },
  currentLimitLabel: "",
  pendingLimitLabel: "",
  appliedLabel: "",
  editButtonText: "",
  cancelButtonText: ""
};

export default PendingLimits;
