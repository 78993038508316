// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, defaultSize } from "../../_static/Typography";

export const Container = styled.div`
  background-color: ${buildColor("white", "100")};
  ${({ device }) =>
    device === "tablet" &&
    css`
      border-radius: 2px;
      box-shadow: 0 4px 8px0 rgba(147, 175, 205, 0.03),
        0 1px 3px0 rgba(99, 119, 140, 0.04);
    `}
  ${({ device }) =>
    device === "mobile" &&
    css`
      margin-top: ${(props) => (props.hasMarginTop ? "8px" : "0")};
      border-radius: 0;
      box-shadow: 0 1px 0 0 rgb(214, 227, 240),
        0 1px 3px 0 rgba(99, 119, 140, 0.04);
    `}
`;

export const FundingMethodBackground = styled.div`
  background-color: ${buildColor("blue", "000")};
  width: 100%;
  ${({ device }) => device !== "desktop" && "height: 100vh;"}
  ${({ device }) =>
    device === "tablet" &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`;

export const Description = styled.span`
  display: block;
  color: ${buildColor("grey", "800")};
  background-color: ${buildColor("white", "100")};
  font-family: ${fontNormal};
  padding: ${({ device }) =>
    device === "tablet" ? "0 36px 16px" : "0 12px 16px"};
  font-size: 14px;
  margin-bottom: 2px;
`;

export const Separator = styled.div`
  width: calc(100% - ${({ fullWidth }) => (fullWidth ? "0" : "12px")});
  height: 1px;
  margin-left: ${({ fullWidth }) => (fullWidth ? "0" : "12px")};
  background-color: ${buildColor("blue", "000")};
`;

export const SectionContainer = styled.section`
  padding: 16px 0 0;
`;

export const SectionEmptyContainer = styled.section`
  padding: 16px;
  border-radius: 0;

  & div p:last-child {
    color: ${buildColor("grey", "800")};
  }
`;

export const EmptyActiveMethodsSupportText = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: ${({ device }) => (device !== "tablet" ? "8px" : "26px")};

  > a {
    text-decoration: none !important;
    color: ${buildColor("blue_accent", "500")} !important;
    font-family: ${fontBold};
    font-weight: bold;
    vertical-align: baseline;
  }
`;

export const ContainerTitle = styled.span`
  color: rgb(21, 42, 71);
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
  padding: 0 ${({ device }) => (device === "mobile" ? "12px" : "16px")};
`;

export const SeparatorBlock = styled.div`
  /* stylelint-disable */
  > ${Separator}:last-child {
    display: none;
  }
`;

export const FundingControlsTitle = styled.h2`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 5px;
  padding: 16px;
  padding-bottom: 0;
`;

export const MoreInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${buildColor("white", "100")};
`;

export const FundingMethodsWrapper = styled.div`
  ${({ device }) =>
    device === "desktop" &&
    css`
      border: 1px solid ${buildColor("blue", "100")};
      background: ${buildColor("white", "100")};
      border-radius: 2px;
      box-shadow: 0 4px 8px 0 rgba(147, 175, 205, 0.03),
        0 1px 3px 0 rgba(99, 119, 140, 0.04);
    `}
  ${({ device }) =>
    device === "tablet" &&
    css`
      max-width: 635px;
      width: 100%;
      margin: 20px 12px 0;
      background: ${buildColor("white", "100")};
      border-radius: 2px;
      border: 1px solid ${buildColor("blue", "100")};
      box-shadow: 0 4px 8px0 rgba(147, 175, 205, 0.03),
        0 1px 3px0 rgba(99, 119, 140, 0.04);
    `}
  ${({ device }) =>
    device === "mobile" &&
    css`
      margin-top: 1px;
    `}
`;

export const FundingMethodsHeader = styled.div`
  ${({ device }) =>
    device === "desktop" &&
    css`
      border: 1px solid ${buildColor("blue", "100")};
      border-bottom: 0;
      background: ${buildColor("white", "100")};
      border-radius: 2px;
      box-shadow: 0 4px 8px 0 rgba(147, 175, 205, 0.03),
        0 1px 3px 0 rgba(99, 119, 140, 0.04);
    `}

  ${({ device }) =>
    device === "tablet" &&
    css`
      width: 100%;
    `}
`;

export const ErrorWrapper = styled.div`
  padding: 40px 0;
  background: ${buildColor("white", "100")};
`;
