export default {
  rgTimeoutsExclusionsDetails: {
    description:
      "Take a break from FanDuel with a Timeout.\n Tell us how much time you need away and we’ll make sure you can only log into FanDuel products after the time out has ended.",
    optionsSelectionTitle: "title",
    optionsSelectionDescription:
      "Please select an amount of time that you would like to take a break for:",
    options: [
      {
        id: "timeout",
        title: "Timeout",
        description: "3-365 DAYS",
        isNew: false,
        icon: "timer"
      },
      {
        id: "selfExclude",
        title: "Self-Exclude",
        description: "1-5 YEARS",
        isNew: false,
        icon: "calendar"
      },
      {
        id: "suspend",
        title: "Suspend",
        description: "ACCOUNT",
        isNew: false,
        icon: "lock"
      }
    ],
    selfExcludeSelectionTitle: "Select Exclusion length",
    selfExcludeSelectionDescription: "description",
    selfExcludeOptions: [
      {
        id: "1year",
        title: "1",
        description: "YEAR",
        isNew: false
      },
      {
        id: "3year",
        title: "3",
        description: "YEARS",
        isNew: false
      },
      {
        id: "5year",
        title: "5",
        description: "YEARS",
        isNew: false
      }
    ],
    moreInfo: [
      {
        id: "timeout",
        title: "A What Timeout means:",
        details: [
          {
            id: "1",
            icon: "lock",
            description: "You can't log into your account"
          },
          {
            id: "2",
            icon: "refresh",
            description:
              "Your account will reactivate automatically at the end of your Timeout"
          },
          {
            id: "3",
            icon: "alertSuspended",
            description: "You won't receive any marketing communications"
          }
        ]
      },
      {
        id: "selfExclude",
        title: "What a Self-exclusion means:",
        details: [
          {
            id: "1",
            icon: "lock",
            description: "You can't log into your account"
          },
          {
            id: "2",
            icon: "refresh",
            description:
              "Your account will reactivate automatically at the end of your Timeout"
          },
          {
            id: "3",
            icon: "alertSuspended",
            description: "You won't receive any marketing communications"
          }
        ]
      },
      {
        id: "suspend",
        title: "Once confirmed:",
        details: [
          {
            id: "1",
            icon: "lock",
            description: "You can't log into your account"
          },
          {
            id: "2",
            icon: "infoOutline",
            description:
              "You'll need to Contact Support with any reactivation or refund requests"
          }
        ]
      }
    ],
    inputValueCfg: {
      inputHeader: "enter timeout length",
      inputPlaceholder: "Between 3-365 Days",
      inputFooterInfo: ""
    },
    submitButtonText: "Submit request"
  }
};

export const optionsWithInvalidIcons = [
  {
    id: "timeout",
    title: "Timeout",
    description: "3-365 DAYS",
    isNew: false,
    icon: "dummy"
  },
  {
    id: "selfExclude",
    title: "Self-Exclude",
    description: "1-5 YEARS",
    isNew: false,
    icon: "info"
  },
  {
    id: "suspend",
    title: "Suspend",
    description: "ACCOUNT",
    isNew: false,
    icon: "lock"
  }
];

export const optionsWithNoIcons = [
  {
    id: "timeout",
    title: "Timeout",
    description: "3-365 DAYS",
    isNew: false
  },
  {
    id: "selfExclude",
    title: "Self-Exclude",
    description: "1-5 YEARS",
    isNew: false
  },
  {
    id: "suspend",
    title: "Suspend",
    description: "ACCOUNT",
    isNew: false
  }
];
