// @flow

import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import {
  fontBold,
  fontMedium,
  fontNormal,
  defaultSize
} from "../../_static/Typography";

export const GroupContainer = styled.div`
  width: 100%;
`;

export const GroupTitle = styled.div`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
  height: 19px;
  letter-spacing: 0;
  padding: 0 12px;
`;

export const OptionsWrapper = styled.div`
  margin-bottom: 17px;
`;

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  height: 20px;
  margin: 17px 0;

  &:hover {
    cursor: pointer;
  }
`;

export const OptionSelectionMark = styled.div`
  background: ${buildColor("blue_accent", "500")};
  border-radius: 2px;
  height: 20px;
  width: 4px;
`;

export const OptionTitle = styled.span`
  color: ${buildColor("grey", "900")};
  font-size: ${defaultSize};
  letter-spacing: 0;
  padding-right: 12px;
  align-self: center;
  ${({ isSelected }) =>
    isSelected
      ? `
      padding-left: 8px;
      font-family:${fontMedium};
      font-weight: 500;
      height: 16px;
      `
      : `
      padding-left: 12px;
      font-family:${fontNormal};
      font-weight: normal;
      height: 18px;
      line-height: 18px;
      `}
`;

export const OptionDescription = styled.p`
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  height: 16px;
  letter-spacing: 0;
  padding: 0 12px;
`;
