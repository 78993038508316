// @flow
import React from "react";
import { noop, capitalize } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";

import Icon from "../../_static/Icons";
import * as allIcons from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

import {
  ItemWrapper,
  IconWrapper,
  SmallIconWrapper,
  ItemMainWrapper,
  ItemMainTitle,
  ItemDescription
} from "./styled-components";

type Props = {
  title: string,
  description: string,
  isActive: boolean,
  isApplied: boolean,
  promptClick: NullaryFn<void>,
  icon: string,
  isSimplified: boolean,
  qaLabel: string
};

const firstWordUpperCase = (text: string) =>
  text.split(" ").map(capitalize).join(" ");

const renderStandard = ({
  title,
  description,
  isActive,
  isApplied,
  promptClick,
  icon,
  qaLabel
}: Props) => (
  <ItemWrapper
    isActive={isActive}
    onClick={promptClick}
    hasIcon={!!icon}
    data-qa-label={`rg-item-${qaLabel}`}
  >
    {icon && allIcons && (
      <IconWrapper isActive={isActive} data-qa-label="rg-item-content-icon">
        <Icon
          icon={allIcons[icon]}
          color={
            isActive
              ? buildColor("blue_accent", "500")
              : buildColor("blue", "400")
          }
          size={14}
          qaLabel="icon"
        />
      </IconWrapper>
    )}
    <ItemMainWrapper data-qa-label="rg-item-content">
      <ItemMainTitle
        hasIcon={!!icon}
        isActive={isActive}
        data-qa-label="rg-item-title"
      >
        {title}
      </ItemMainTitle>
      {isApplied && (
        <SmallIconWrapper data-qa-label="rg-item-content-applied-icon">
          <Icon
            icon={allIcons.tick}
            color={buildColor("white", "100")}
            size={14}
            qaLabel="rg-item-icon"
          />
        </SmallIconWrapper>
      )}
    </ItemMainWrapper>
    <ItemDescription data-qa-label="rg-item-description" isActive={isActive}>
      {firstWordUpperCase(description)}
    </ItemDescription>
  </ItemWrapper>
);

const renderSimplified = ({
  qaLabel,
  title,
  description,
  isActive,
  isApplied,
  promptClick
}: Props) => (
  <ItemWrapper
    isSmaller
    isActive={isActive}
    onClick={promptClick}
    data-qa-label={`rg-item-${qaLabel}`}
  >
    <ItemMainWrapper data-qa-label="rg-item-content">
      <ItemMainTitle isActive={isActive} data-qa-label="rg-item-title">
        {title}
      </ItemMainTitle>
      {isApplied && (
        <SmallIconWrapper
          isActive={isActive}
          data-qa-label="rg-item-content-applied-icon"
        >
          <Icon
            qaLabel="rg-item-icon"
            icon={allIcons.tick}
            color={
              isActive ? buildColor("white", "100") : buildColor("blue", "500")
            }
            size={14}
          />
        </SmallIconWrapper>
      )}
    </ItemMainWrapper>
    <ItemDescription data-qa-label="rg-item-description" isActive={isActive}>
      {firstWordUpperCase(description)}
    </ItemDescription>
  </ItemWrapper>
);

const Item = (props: Props) =>
  props.isSimplified ? renderSimplified(props) : renderStandard(props);

Item.defaultProps = {
  title: "",
  description: "",
  isActive: false,
  isApplied: false,
  promptClick: noop,
  icon: null,
  isSimplified: false,
  qaLabel: "item"
};

// $FlowFixMe
export default React.memo(Item);
