// @flow
// $FlowFixMe
import React, { Fragment } from "react";
import { get, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";
import { DepositLimitsMask } from "../../_static/Masks";
import Info from "../../_molecule/Info";
import DepositLimitSelection from "../../_organism/RGItemSelection";
import PendingLimits from "../../_molecule/PendingLimits";
import SubmitLimits from "../../_molecule/SubmitLimits";
import {
  DepositLimitWrapper,
  DepositLimitTitle,
  DepositLimitSelectionWrapper,
  MoreInfoWrapper,
  MoreInfo,
  Details,
  InputSeparator,
  ErrorWrapper
} from "./styled-components";

export type Limits = {
  previousLimit: ?string,
  pendingLimit: ?string,
  pendingLimitDate: ?string,
  newLimit: ?string,
  applyDate: ?string
};

type Props = {
  device: Device,
  isLoading: boolean,
  index: number,
  indexesApplied: number[],
  updateIndex: UnaryFn<number, void>,
  isActive: boolean,
  canSubmit: boolean,
  depositLimitTitle: string,
  depositLimitTitleInfo: string,
  depositLimitSubTitleInfo: string,
  depositLimitFooterInfo: string,
  depositLimitPeriods: ListItemType[],
  depositLimitSubmit: {
    title: string,
    placeholder: string,
    buttonText: string
  },
  pendingLimitsConfig: {
    currentLimitLabel: string,
    pendingLimitLabel: string,
    appliedLabel: string,
    editButtonText: string,
    cancelButtonText: string
  },
  handleInput: UnaryFn<Event, void>,
  targetAmount: string,
  toggleActive: NullaryFn<void>,
  toggleNotActive: NullaryFn<void>,
  onCleanInputHandler: NullaryFn<void>,
  onSubmitClick: NullaryFn<void>,
  isInputActive: boolean,
  toggleInputCallback: UnaryFn<boolean, void>,
  limits: Limits,
  hasError: boolean,
  onErrorButtonCallback: NullaryFn<void>,
  capiMessages: *
};

const renderPendingLimits = (
  limits: Limits,
  isInputActive: boolean,
  toggleInputCallback: UnaryFn<boolean, void>,
  pendingLimitsConfig: {
    currentLimitLabel: string,
    pendingLimitLabel: string,
    appliedLabel: string,
    editButtonText: string,
    cancelButtonText: string
  },
  device: Device
) => {
  const pendingLimitsProps = {
    isInputActive,
    toggleInputCallback,
    currentLimit: {
      value: get(limits, "previousLimit")
    },
    pendingLimit: {
      value: get(limits, "pendingLimit"),
      pendingLimitDate: get(limits, "pendingLimitDate")
    },
    ...pendingLimitsConfig,
    device
  };
  return <PendingLimits {...pendingLimitsProps} />;
};

const DepositLimits = ({
  device,
  isLoading,
  index,
  indexesApplied,
  updateIndex,
  isActive,
  canSubmit,
  depositLimitTitle,
  depositLimitTitleInfo,
  depositLimitSubTitleInfo,
  depositLimitFooterInfo,
  depositLimitPeriods,
  depositLimitSubmit,
  pendingLimitsConfig,
  handleInput,
  targetAmount,
  toggleActive,
  toggleNotActive,
  onCleanInputHandler,
  onSubmitClick,
  isInputActive,
  toggleInputCallback,
  limits,
  hasError,
  onErrorButtonCallback,
  capiMessages
}: Props) =>
  hasError ? (
    <ErrorWrapper>
      <Info
        messageType="error"
        title={get(capiMessages, "componentError.title")}
        description={get(capiMessages, "componentError.description")}
        full
        hasButton
        buttonHandler={onErrorButtonCallback}
        qaLabel="DLRequestError"
      />
    </ErrorWrapper>
  ) : (
    <DepositLimitWrapper device={device}>
      <MoreInfoWrapper>
        {device === "desktop" && (
          <DepositLimitTitle>{depositLimitTitle}</DepositLimitTitle>
        )}
        <MoreInfo data-qa-label="moreInfoTitle" device={device}>
          {depositLimitTitleInfo}
        </MoreInfo>
      </MoreInfoWrapper>
      <DepositLimitSelectionWrapper
        data-qa-label="depositLimitsWrapper"
        device={device}
      >
        {isLoading ? (
          <DepositLimitsMask />
        ) : (
          <Details device={device}>
            <DepositLimitSelection
              indexSelected={index - 1}
              indexSelectedUpdate={(indexSelected: number) =>
                updateIndex(indexSelected + 1)
              }
              indexesApplied={indexesApplied.map(
                (indexApplied) => indexApplied - 1
              )}
              itemSelectionDescription={depositLimitSubTitleInfo}
              items={depositLimitPeriods}
            />
            {index !== 0 && (
              <Fragment>
                {renderPendingLimits(
                  limits,
                  isInputActive,
                  toggleInputCallback,
                  pendingLimitsConfig,
                  device
                )}
                {isInputActive && get(limits, "previousLimit", false) && (
                  <InputSeparator />
                )}
                <SubmitLimits
                  title={depositLimitSubmit.title}
                  placeholder={depositLimitSubmit.placeholder}
                  submitButtonText={depositLimitSubmit.buttonText}
                  isActive={isActive}
                  isVisible={isInputActive}
                  canSubmit={canSubmit}
                  footerInfo={depositLimitFooterInfo}
                  targetAmount={targetAmount}
                  handleInput={handleInput}
                  toggleActive={toggleActive}
                  toggleNotActive={toggleNotActive}
                  onCleanInputHandler={onCleanInputHandler}
                  onSubmitClick={onSubmitClick}
                />
              </Fragment>
            )}
          </Details>
        )}
      </DepositLimitSelectionWrapper>
    </DepositLimitWrapper>
  );

DepositLimits.defaultProps = {
  device: "mobile",
  isLoading: true,
  index: 0,
  indexesApplied: [],
  updateIndex: noop,
  isActive: false,
  canSubmit: false,
  isInputActive: true,
  setIsInputActive: noop,
  depositLimitTitle: "",
  depositLimitTitleInfo: "",
  depositLimitSubTitleInfo: "",
  depositLimitFooterInfo: "",
  depositLimitPeriods: [],
  depositLimitSubmit: {
    title: "",
    placeholder: "",
    buttonText: ""
  },
  pendingLimitsConfig: {
    currentLimitLabel: "",
    pendingLimitLabel: "",
    appliedLabel: "",
    editButtonText: "",
    cancelButtonText: ""
  },
  handleInput: noop,
  targetAmount: "",
  toggleActive: noop,
  toggleNotActive: noop,
  onCleanInputHandler: noop,
  onSubmitClick: noop,
  limits: {
    previousLimit: null,
    pendingLimit: null,
    pendingLimitDate: null,
    newLimit: null,
    applyDate: null
  },
  hasError: false,
  onErrorButtonCallback: noop,
  capiMessages: {
    componentError: {
      title: "",
      description: "",
      buttonText: ""
    }
  }
};

export default DepositLimits;
