// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold, fontNormal, defaultSize } from "../../_static/Typography";

export const RGContainer = styled.div`
  width: 100%;
  height: 100%;
`;

// TODO: fix box-shadow colors
export const RGHeader = styled.div`
  background: ${buildColor("white", "100")};
  box-shadow: 0 4px 8px 0 rgba(147, 175, 205, 0.03),
    0 1px 3px 0 rgba(99, 119, 140, 0.04);
  border-radius: 0;
  padding: 20px;
`;

export const RGHeaderContent = styled.div`
  max-width: 1256px;
  margin: auto;
`;

export const RGTitle = styled.h1`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 31px;
  font-weight: bold;
  letter-spacing: 0;
  max-width: 655px;
`;

export const RGDescription = styled.p`
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
  width: 100%;
  max-width: 655px;
  padding-top: 12px;
  white-space: pre-line;
`;

export const RGMain = styled.div`
  padding: 20px 0;
  display: flex;
  max-width: 1256px;
  margin: auto;
`;

// TODO: fix background
export const RGNavbar = styled.div`
  width: 241px;
  margin-top: 20px;
`;

// TODO: fix border and box-shadow colors
export const RGContent = styled.div`
  flex: 1;
`;

export const ContainerFooter = styled.div`
  color: ${buildColor("grey", "700")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  flex-grow: 1;
  overflow: auto;

  & * a {
    vertical-align: baseline;
    text-decoration: none;
  }
`;

export const FooterTitle = styled.h3`
  margin-bottom: 4px;
  font-size: 16px;
  font-family: ${fontBold};
  font-weight: bold;
  letter-spacing: 0;
`;
