// @flow
// $FlowFixMe
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { get, debounce, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import mediator from "@tvg/mediator";
import type { NullaryFn, UnaryFn, BinaryFn } from "@tvg/types/Functional";
import RGTimeoutTemplate from "@tvg/atomic-ui/_templates/RGTimeout";
import type { SubmitInputValueType } from "@tvg/atomic-ui/_templates/RGTimeout";
import { rgTimeoutExclusionSplash } from "@tvg/location-splash/actions";
import type { ListItemType, IconDescriptionType } from "@tvg/types/ListItem";
import RGTimeoutLib, {
  emitTimeoutGtmClick,
  modalClickGtmHandle,
  type RGTimeoutExclusionType
} from "@tvg/sh-lib-rg-timeout";
import { parseCAPIMessage } from "@tvg/utils/capiUtils";
import {
  rgSuspensionModalOpen,
  rgTimeoutModalOpen,
  rgSelfExclusionModalOpen,
  rgSetLoading,
  rgSubmitModalError,
  rgCloseModal
} from "@tvg/responsible-gaming-modal/src/actions";
import rgDefaultConfig from "@tvg/sh-lib-rg-timeout/__mocks__/rgTimeoutDetails";
import { getAccountNumber } from "@urp/store-selectors";

type MoreInfoType = {
  id: string,
  title: string,
  details: IconDescriptionType[]
};

type ExclusionModalType = {
  period: number,
  endDate: string,
  callback: NullaryFn<void>
};

type Props = {
  device: Device,
  isLogged: boolean,
  accountNumber: number,
  optionsIdxSelected: number,
  selfExcludeIdxSelected: number,
  timeoutValue: number,
  rgTimeoutsExclusionsDetails: {
    title: string,
    description: string,
    optionsSelectionTitle: string,
    optionsSelectionDescription: string,
    options: ListItemType[],
    selfExcludeSelectionTitle: string,
    selfExcludeSelectionDescription: string,
    selfExcludeOptions: ListItemType[],
    moreInfo: MoreInfoType[],
    inputValueCfg: SubmitInputValueType,
    submitButtonText: string
  },
  timeoutModalOpen: UnaryFn<ExclusionModalType, void>,
  selfExclusionModalOpen: UnaryFn<ExclusionModalType, void>,
  suspensionModalOpen: UnaryFn<NullaryFn<void>, void>,
  setIsLoading: UnaryFn<boolean, void>,
  setModalClose: NullaryFn<void>,
  setModalError: NullaryFn<void>,
  showTimeoutExclusionSplash: BinaryFn<string, string, void>,
  optionSelectedHandler: UnaryFn<string, mixed>
};

export const RGTimeout = ({
  device,
  accountNumber,
  isLogged,
  optionsIdxSelected,
  selfExcludeIdxSelected,
  timeoutValue,
  rgTimeoutsExclusionsDetails,
  timeoutModalOpen,
  selfExclusionModalOpen,
  suspensionModalOpen,
  setIsLoading,
  setModalClose,
  setModalError,
  showTimeoutExclusionSplash,
  optionSelectedHandler
}: Props) => {
  const {
    title,
    description,
    optionsSelectionTitle,
    optionsSelectionDescription,
    options,
    selfExcludeSelectionTitle,
    selfExcludeSelectionDescription,
    selfExcludeOptions,
    moreInfo,
    inputValueCfg,
    submitButtonText
  } = rgTimeoutsExclusionsDetails;
  const [optionIdx, setOptionIdx] = useState(optionsIdxSelected);
  const [selfExcludeIdx, setSelfExcludeIdx] = useState(selfExcludeIdxSelected);
  const [timeoutInputValue, setTimeoutInputValue] = useState(timeoutValue);
  const [timeoutValid, setTimeoutValid] = useState(true);

  const timeoutExclusionCallback = useCallback(
    (
      exclusionType: string,
      periodInDays?: number,
      accountNumberFromLogin?: number
    ) =>
      RGTimeoutLib.applyTimeoutExclusion(
        accountNumberFromLogin || accountNumber,
        exclusionType,
        periodInDays,
        setIsLoading,
        setModalClose,
        setModalError,
        showTimeoutExclusionSplash,
        device === "desktop"
      ),
    [accountNumber]
  );

  const debounceTimeoutValidation = debounce(
    (value) => setTimeoutValid(RGTimeoutLib.validateTimout(value)),
    500
  );

  const handleOptionSelected = (index: number): void => {
    if (index < RGTimeoutLib.EXCLUSION_TYPES.length) {
      setOptionIdx(index);
      if (optionSelectedHandler)
        optionSelectedHandler(RGTimeoutLib.getExclusionType(index));
    }
    setTimeoutInputValue("");
  };

  const handleSelfExcludeSelected = (index: number): void => {
    if (index < RGTimeoutLib.SELF_EXCLUDE_YEARS.length)
      setSelfExcludeIdx(index);
  };

  const handleInputChange = (event: Event): void => {
    const newTimeout = get(event, "target.value", "").replace(/\D/, "");
    setTimeoutInputValue(newTimeout);
    debounceTimeoutValidation.cancel();
    debounceTimeoutValidation(newTimeout);
  };

  const handleCanSubmit = () =>
    (RGTimeoutLib.validateTimout(timeoutInputValue) && timeoutValid) ||
    optionIdx > 0;

  const handleSubmit = (): void => {
    const handle = (accountNumberFromLogin?: string) => {
      const exclusionType = RGTimeoutLib.getExclusionType(optionIdx);
      if (exclusionType === RGTimeoutLib.TIMEOUT) {
        timeoutModalOpen({
          period: timeoutInputValue,
          endDate: RGTimeoutLib.getEndDateFormated(timeoutInputValue),
          callback: () =>
            timeoutExclusionCallback(
              exclusionType,
              timeoutInputValue,
              accountNumberFromLogin
            )
        });
      } else if (exclusionType === RGTimeoutLib.SELF_EXCLUSION) {
        const selfExcludeYears =
          RGTimeoutLib.getSelfExcludeYears(selfExcludeIdx);
        const periodInDays =
          RGTimeoutLib.calcPeriodInDays(
            exclusionType,
            selfExcludeYears,
            timeoutInputValue
          ) || RGTimeoutLib.YEAR_DAYS;
        selfExclusionModalOpen({
          period: selfExcludeYears,
          endDate: RGTimeoutLib.getEndDateFormated(periodInDays),
          callback: () =>
            timeoutExclusionCallback(
              exclusionType,
              periodInDays,
              accountNumberFromLogin
            )
        });
      } else if (exclusionType === RGTimeoutLib.SUSPEND) {
        suspensionModalOpen(() =>
          timeoutExclusionCallback(
            exclusionType,
            undefined,
            accountNumberFromLogin
          )
        );
      }

      emitTimeoutGtmClick(
        options,
        optionIdx,
        timeoutInputValue,
        selfExcludeIdx
      );
    };

    if (!isLogged) {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (get(success, "status", "fail") === "success") {
              handle(get(success, "data.userDetails.accountNumber", 0));
            }
          }
        }
      });
    } else {
      handle();
    }
  };

  useEffect(() => {
    handleOptionSelected(optionsIdxSelected);
  }, [optionsIdxSelected]);

  const optionsWithIconsVerified = RGTimeoutLib.verifyIcons(
    options,
    get(rgDefaultConfig, "rgTimeoutsExclusionsDetails.options", []),
    ["info"]
  );

  let iconList = RGTimeoutLib.verifyIcons(
    get(moreInfo, `${optionIdx}.details`),
    get(
      rgDefaultConfig,
      `rgTimeoutsExclusionsDetails.moreInfo.${optionIdx}.details`,
      []
    ),
    ["info"]
  );

  if (get(moreInfo, `${optionIdx}.id`) === "selfExclude") {
    iconList = (iconList || []).map((selfExcludeIcon) => ({
      ...selfExcludeIcon,
      icon: selfExcludeIcon.icon === "refresh" ? "phone" : selfExcludeIcon.icon
    }));
  }

  return useMemo(
    () => (
      <React.Fragment>
        <RGTimeoutTemplate
          title={title}
          device={device}
          description={description}
          options={optionsWithIconsVerified}
          optionsIdxSelected={optionIdx}
          optionsIdxSelectedUpdate={handleOptionSelected}
          optionsSelectionTitle={optionsSelectionTitle}
          optionsSelectionDescription={optionsSelectionDescription}
          selfExcludeOptions={selfExcludeOptions}
          isSelfExcludeOptionsShown={
            RGTimeoutLib.getExclusionType(optionIdx) ===
            RGTimeoutLib.SELF_EXCLUSION
          }
          selfExcludeIdxSelected={selfExcludeIdx}
          selfExcludeIdxSelectedUpdate={handleSelfExcludeSelected}
          selfExcludeSelectionTitle={selfExcludeSelectionTitle}
          selfExcludeSelectionDescription={selfExcludeSelectionDescription}
          iconsListTitle={get(moreInfo, `${optionIdx}.title`)}
          iconsList={iconList}
          inputValue={timeoutInputValue}
          isInputValueShown={
            RGTimeoutLib.getExclusionType(optionIdx) === RGTimeoutLib.TIMEOUT
          }
          isInputValueActive={
            RGTimeoutLib.getExclusionType(optionIdx) === RGTimeoutLib.TIMEOUT
          }
          isInputValueValid={timeoutValid}
          handleInputChange={handleInputChange}
          inputValueCfg={inputValueCfg}
          submitButtonHandle={() => handleSubmit()}
          submitButtonText={submitButtonText}
          canSubmit={handleCanSubmit()}
        />
      </React.Fragment>
    ),
    [
      accountNumber,
      optionIdx,
      selfExcludeIdx,
      timeoutInputValue,
      optionsIdxSelected,
      handleSubmit,
      JSON.stringify(rgTimeoutsExclusionsDetails)
    ]
  );
};

RGTimeout.defaultProps = {
  device: "mobile",
  optionsIdxSelected: 0,
  selfExcludeIdxSelected: 0,
  timeoutValue: "",
  optionSelectedHandler: noop
};

const mapStateToProps = (store) => ({
  isLogged: get(store, "userData.logged", false),
  accountNumber: parseInt(getAccountNumber(store), 10),
  rgTimeoutsExclusionsDetails: parseCAPIMessage(
    store,
    "capi.messages.rgTimeoutsExclusionsDetails",
    {}
  )
});

const closeModal = (dispatch: Dispatch<*>) => dispatch(rgCloseModal());

export const mapDispatchToProps = (dispatch: Dispatch<*>, ownProps: Props) => ({
  timeoutModalOpen: ({ period, endDate, callback }: ExclusionModalType) =>
    dispatch(
      rgTimeoutModalOpen(period, endDate, callback, () => {
        modalClickGtmHandle("cancel");
        closeModal(dispatch);
      })
    ),
  selfExclusionModalOpen: ({ period, endDate, callback }: ExclusionModalType) =>
    dispatch(
      rgSelfExclusionModalOpen(period, endDate, callback, () => {
        modalClickGtmHandle("cancel");
        closeModal(dispatch);
      })
    ),
  suspensionModalOpen: (callback: NullaryFn<void>) =>
    dispatch(
      rgSuspensionModalOpen(callback, () => {
        modalClickGtmHandle("cancel");
        closeModal(dispatch);
      })
    ),
  setIsLoading: (isLoading: boolean) => dispatch(rgSetLoading(isLoading)),
  setModalClose: () => closeModal(dispatch),
  setModalError: () => dispatch(rgSubmitModalError()),
  showTimeoutExclusionSplash: (
    type: RGTimeoutExclusionType,
    endDate: string
  ) => {
    if (ownProps.device === "desktop") {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: {
          route: "/"
        }
      });
    } else {
      dispatch(rgTimeoutExclusionSplash(type, endDate));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RGTimeout);
