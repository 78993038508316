// @flow
import type { PaymentOptions } from "@tvg/types/ResponsibleGaming";

export const capiMessagesDefault = {
  fundingControls: {
    headerMessage: "",
    activeMethodsTitle: "",
    blockLiftedMessage: "",
    blockMessage: "",
    blockedMethodsTitle: "",
    addNewPaymentMethod: "",
    noActivePaymentsTitle: "",
    noActivePaymentsMessage: "",
    livePersonMessage: "",
    fundingControlsTitle: "",
    componentError: {
      title: "",
      subTitle: "",
      buttonText: ""
    }
  }
};

export const paymentOptionsDefault: PaymentOptions = {
  ACH: "eCheck",
  CASH: "Cash",
  CC: "Credit Card",
  CCS: "Credit Card Signed",
  CHK: "Check",
  DEB: "Debit Card",
  IBC: "eCheck",
  MPAK: "MoneyPak",
  PAY: "PayPal",
  APPL: "Apple Pay",
  PNM: "PayNearMe",
  PPAL: "PayPal",
  SLP: "TVG Pre Paid",
  MZM: "Mazooma"
};

export const costumerSupportURL: string = "https://support.tvg.com/s/";
