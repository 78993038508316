// @flow
import React, { PureComponent, Fragment } from "react";
import type { RouterHistory } from "react-router-dom";
import DepositLimits from "@tvg/deposit-limits";
import FundingMethods from "@tvg/funding-methods";
import ResponsibleGaming from "@tvg/responsible-gaming";
import TimeoutExclusions from "@tvg/rg-timeout";
import type { UnaryFn } from "@tvg/types/Functional";
import RG_OPTIONS from "@tvg/types/ResponsibleGaming";
import type { Device } from "@tvg/types/Device";

import perf from "../../utils/perf";
import { Wrapper } from "./styled-components";
import isRn, { isRnTablet } from "../../utils/isRn";

type Props = {
  isEnabled: boolean,
  hasRgLoggedOutToggle: boolean,
  isLogged: boolean,
  history: RouterHistory,
  device: Device,
  product: string,
  currentPath: string,
  isRn: boolean,
  dispatch: Dispatch<*>
};

function renderContent(
  rgOption: string,
  device: Device,
  optionSelectedHandler: UnaryFn<string, void>
) {
  switch (rgOption) {
    case RG_OPTIONS.DEPOSIT_LIMIT:
      return <DepositLimits device={device} />;
    case RG_OPTIONS.FUNDING_CONTROLS:
      return <FundingMethods device={device} depositURL="/my-funds/deposit" />;
    case RG_OPTIONS.TIMEOUT:
      return (
        <TimeoutExclusions
          device={device}
          optionsIdxSelected={0}
          optionSelectedHandler={optionSelectedHandler}
        />
      );
    case RG_OPTIONS.SELF_EXCLUSION:
      return (
        <TimeoutExclusions
          device={device}
          optionsIdxSelected={1}
          optionSelectedHandler={optionSelectedHandler}
        />
      );
    case RG_OPTIONS.SUSPEND:
      return (
        <TimeoutExclusions
          device={device}
          optionsIdxSelected={2}
          optionSelectedHandler={optionSelectedHandler}
        />
      );
    default:
      return <div>TODO: Something went wrong!!!</div>;
  }
}

export default class MicroApp extends PureComponent<Props, *> {
  state = {
    optionSelected: RG_OPTIONS.DEPOSIT_LIMIT
  };

  componentDidUpdate() {
    const { isLogged, hasRgLoggedOutToggle } = this.props;

    perf.measure("page_render", { page: "responsible_gaming" });

    if (
      !isLogged &&
      typeof hasRgLoggedOutToggle === "boolean" &&
      !hasRgLoggedOutToggle
    ) {
      this.props.history.push("/");
      window.location.reload(true);
    }
  }

  optionSelectedHandler(option: string) {
    if (RG_OPTIONS[option] && this.state.optionSelected !== option) {
      this.setState({ optionSelected: option });
    }
  }

  render() {
    const { isEnabled } = this.props;
    return (
      isEnabled && (
        <Fragment>
          <Wrapper isRn={isRn || isRnTablet}>
            <ResponsibleGaming
              device={this.props.device}
              optionSelected={this.state.optionSelected}
              optionSelectedHandler={(option) =>
                this.setState({ optionSelected: option })
              }
            >
              {renderContent(
                this.state.optionSelected,
                this.props.device,
                this.optionSelectedHandler.bind(this)
              )}
            </ResponsibleGaming>
          </Wrapper>
        </Fragment>
      )
    );
  }
}
