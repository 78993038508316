// @flow
// $FlowFixMe
import React, { Fragment, type Node, useMemo } from "react";
import type { IconDescriptionType } from "@tvg/types/ListItem";
import IconDescription from "../../_molecule/IconDescription";

import IconsListWrapper, {
  IconsListDescription,
  IconsListElement,
  IconListTitle
} from "./styled-components";

type Props = {
  title: string,
  description: ?string,
  iconsList: IconDescriptionType[]
};

const renderList = (iconsList: IconDescriptionType[]): Node =>
  iconsList.map((item: IconDescriptionType) => (
    <IconsListElement
      key={`iconsList_${item.id}`}
      data-qa-label={`Icon-${item.id}`}
    >
      <IconDescription
        id={item.id}
        icon={item.icon}
        description={item.description}
        data-qa-label="IconsListDescription"
      />
    </IconsListElement>
  ));

const IconsList = (props: Props) => {
  const { title, description, iconsList } = props;
  return useMemo(
    () => (
      <Fragment>
        <IconListTitle data-qa-label="IconsListTitle">{title}</IconListTitle>

        {description && (
          <IconsListDescription data-qa-label="IconsListDescription">
            {description}
          </IconsListDescription>
        )}

        <IconsListWrapper data-qa-label="iconsList">
          {renderList(iconsList)}
        </IconsListWrapper>
      </Fragment>
    ),
    [JSON.stringify(iconsList)]
  );
};

IconsList.defaultProps = {
  title: "",
  description: null,
  iconsList: []
};

export default IconsList;
