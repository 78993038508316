// @flow
// $FlowFixMe
import React, { useMemo } from "react";
import type { UnaryFn } from "@tvg/types/Functional";
import { noop } from "lodash";
import type { ListItemType } from "@tvg/types/ListItem";

import ItemGroupWrapper from "./styled-components";
import Item from "../../_atom/RGItem";

type Props = {
  indexSelected: number,
  indexSelectedUpdate: UnaryFn<number, void>,
  indexesApplied: number[],
  items: ListItemType[],
  isSimplified: boolean,
  className: string
};

const ItemGroup = ({
  indexSelected,
  indexSelectedUpdate,
  indexesApplied,
  items,
  isSimplified,
  className
}: Props) =>
  useMemo(
    () => (
      <ItemGroupWrapper className={className} data-qa-label="rg-item-group">
        {items.map((item, idx) => (
          <Item
            qaLabel={`rg-item-group-item-${item.id}`}
            key={item.id}
            title={item.title}
            description={item.description}
            isActive={idx === indexSelected}
            isApplied={indexesApplied.includes(idx)}
            icon={item.icon}
            promptClick={() => indexSelectedUpdate(idx)}
            isSimplified={isSimplified}
          />
        ))}
      </ItemGroupWrapper>
    ),
    [
      indexSelected,
      indexSelectedUpdate,
      JSON.stringify(items),
      JSON.stringify(indexesApplied)
    ]
  );

ItemGroup.defaultProps = {
  indexSelected: 0,
  indexSelectedUpdate: noop,
  indexesApplied: [],
  items: [],
  isSimplified: false,
  className: ""
};

export default ItemGroup;
