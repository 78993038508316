// @flow
// $FlowFixMe
import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { get, noop } from "lodash";
import type { RouterHistory } from "react-router-dom";
import mediator from "@tvg/mediator";
import { arrowBack } from "@tvg/atomic-ui/_static/Icons/icons";
import Icon from "@tvg/atomic-ui/_static/Icons";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { HeaderContainer, Title } from "./styled-components";

type Props = {
  history: RouterHistory
};

const Header = (props: Props) => {
  const [isRoot, setIsRoot] = useState(true);
  const [title, setTitle] = useState("Responsible Gaming");
  const handleNavigation = useCallback(
    (e: any) => {
      if (isRoot) {
        e.stopPropagation();
        e.preventDefault();
        mediator.base.dispatch({
          type: "HEADER_GO_BACK",
          payload: {
            gaEventLabel: "back to",
            locationPathname: get(window, "location.pathname"),
            destinationUrl: "/",
            microApp: "RG"
          }
        });
        mediator.base.dispatch({ type: "LEAVE_RG" });
        return;
      }
      mediator.base.dispatch({
        type: "HEADER_GO_BACK",
        payload: {
          gaEventLabel: "back to",
          locationPathname: get(window, "location.pathname"),
          destinationUrl: "/responsible-gaming",
          microApp: "RG"
        }
      });
      setIsRoot(true);
    },
    [isRoot]
  );
  useEffect(() => {
    mediator.base.subscribe("LEAVE_RG", noop);
  }, []);
  useEffect(() => {
    let tempURLLocation = props.history.location.pathname;
    if (tempURLLocation[tempURLLocation.length - 1] === "/") {
      tempURLLocation = tempURLLocation.slice(0, -1);
    }
    const unparsedTitle = tempURLLocation.split("/");
    let parsedTitle = unparsedTitle[unparsedTitle.length - 1].replace("-", " ");
    if (parsedTitle.toLowerCase() !== "responsible gaming") {
      setIsRoot(false);
    }
    if (
      parsedTitle.toLowerCase().includes("timeout") ||
      parsedTitle.toLowerCase().includes("exclude") ||
      parsedTitle.toLowerCase().includes("suspend")
    ) {
      parsedTitle = "TIMEOUT & EXCLUSIONS";
    }
    setTitle(parsedTitle);
  }, [isRoot, props.history.location.pathname]);
  return (
    <HeaderContainer isRoot={isRoot}>
      <Link to="/responsible-gaming" onClick={handleNavigation}>
        <Icon
          icon={arrowBack}
          color={
            isRoot ? buildColor("white", "100") : buildColor("grey", "900")
          }
          size={16}
        />
      </Link>
      <Title isRoot={isRoot}>{title}</Title>
    </HeaderContainer>
  );
};

export default Header;
