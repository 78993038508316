// @flow

import { get } from "lodash";
import RG_OPTIONS from "@tvg/types/ResponsibleGaming";
import formatDateInDaysOrYears from "../../pages/utils/format";
import type { RgModalActions } from "../../actions/modal";

export type State = {
  isModalOpen: boolean,
  responsibleGaming: {
    isOpen: boolean,
    state: string | null,
    status: string,
    type: string,
    isProcessing: boolean,
    timeoutPeriod?: {
      period: string,
      endDate: string
    },
    fundingMethod: string,
    dailyLimit: {
      new: string,
      current: string | null,
      appliedDate: string | null
    }
  }
};

export const initialState = {
  isModalOpen: false,
  responsibleGaming: {
    isOpen: false,
    state: null,
    status: "confirm",
    type: RG_OPTIONS.DEPOSIT_LIMIT,
    isProcessing: false,
    timeoutPeriod: {
      period: "",
      endDate: ""
    },
    fundingMethod: "",
    dailyLimit: {
      new: "$0",
      current: null,
      appliedDate: null
    }
  }
};

const modalReducer = (
  state: State = initialState,
  action: RgModalActions
): State => {
  switch (action.type) {
    case "RG_MODAL_DEPOSIT_LIMIT_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.DEPOSIT_LIMIT,
          dailyLimit: {
            new: get(
              action,
              "payload.newLimit",
              initialState.responsibleGaming.dailyLimit.new
            ),
            current: get(
              action,
              "payload.currentLimit",
              initialState.responsibleGaming.dailyLimit.current
            ),
            appliedDate: get(
              action,
              "payload.appliedDate",
              initialState.responsibleGaming.dailyLimit.appliedDate
            )
          }
        }
      };
    case "RG_MODAL_FUNDING_CONTROL_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.FUNDING_CONTROLS,
          fundingMethod: get(action, "payload.fundingMethod")
        }
      };
    case "RG_MODAL_TIMEOUT_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.TIMEOUT,
          timeoutPeriod: {
            period: get(action, "payload.period"),
            endDate: formatDateInDaysOrYears(
              get(action, "payload.period"),
              get(action, "payload.endDate"),
              true
            )
          }
        }
      };
    case "RG_MODAL_SELF_EXCLUSION_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.SELF_EXCLUSION,
          timeoutPeriod: {
            period: get(action, "payload.period"),
            endDate: formatDateInDaysOrYears(
              get(action, "payload.period"),
              get(action, "payload.endDate"),
              false
            )
          }
        }
      };
    case "RG_MODAL_SUSPENSION_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.SUSPEND,
          timeoutPeriod: {
            period: get(action, "payload.period"),
            endDate: get(action, "payload.endDate")
          }
        }
      };
    case "RG_MODAL_SUBMIT":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "submitted"
        }
      };
    case "RG_MODAL_CANCEL":
      return {
        ...state,
        responsibleGaming: initialState.responsibleGaming
      };
    case "RG_MODAL_FINISHED":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "finished",
          isFinished: true,
          status: "success"
        }
      };
    case "RG_MODAL_ERROR":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "error",
          status: "error"
        }
      };
    case "RG_MODAL_CLOSE":
      return {
        ...state,
        responsibleGaming: initialState.responsibleGaming
      };
    case "RG_MODAL_PROCESSING":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          isProcessing: get(action, "payload.isProcessing")
        }
      };
    default: {
      return state;
    }
  }
};

export default modalReducer;
