// @flow
import React, { PureComponent } from "react";
import type { Device } from "@tvg/types/Device";
import RGTimeoutComponent from "@tvg/rg-timeout";
import Routes from "./Routes";

import perf from "../utils/perf";

const OPTIONS_IDX_DEFAULT = 0;
const TIMEOUT_KEY = "rgTimeout";
const SELF_EXCLUDE_KEY = "rgSelfExclude";
const SUSPEND_ACCOUNT_KEY = "rgSuspendAccount";
const OPTIONS_KEYS = [TIMEOUT_KEY, SELF_EXCLUDE_KEY, SUSPEND_ACCOUNT_KEY];
const TIMEOUT_EXCLUSIONS_ROUTES = Routes.filter((route) =>
  OPTIONS_KEYS.includes(route.key)
);

export const getRouteConfig = (locationPathname: string) => {
  const [routeConfig] = TIMEOUT_EXCLUSIONS_ROUTES.filter(
    (route) => route.path === locationPathname
  );
  return routeConfig;
};

type Props = {
  device: Device,
  currentPath: string
};

class RGTimeout extends PureComponent<Props> {
  static defaultProps = {
    device: "mobile",
    currentPath: ""
  };

  componentDidUpdate() {
    perf.measure("page_render", { page: "rg-timeout" });
  }

  render() {
    const routeConfig = getRouteConfig(this.props.currentPath);
    const optionsIdxSelected = routeConfig
      ? OPTIONS_KEYS.indexOf(routeConfig.key)
      : OPTIONS_IDX_DEFAULT;
    // $FlowFixMe
    return (
      <RGTimeoutComponent
        {...this.props}
        device={this.props.device}
        optionsIdxSelected={optionsIdxSelected}
      />
    );
  }
}

export default RGTimeout;
