// @flow
import React, { Fragment } from "react";
import { get, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { NullaryFn, UnaryFn, BinaryFn } from "@tvg/types/Functional";
import type {
  Method,
  CapiMessages,
  PaymentOptions
} from "@tvg/types/ResponsibleGaming";
import {
  Container,
  Description,
  Separator,
  SectionContainer,
  SectionEmptyContainer,
  EmptyActiveMethodsSupportText,
  ContainerTitle,
  SeparatorBlock,
  MoreInfo,
  FundingControlsTitle,
  FundingMethodsWrapper,
  ErrorWrapper,
  FundingMethodBackground,
  FundingMethodsHeader
} from "./styled-components";
import { plus, contests } from "../../_static/Icons/icons";
import { FundingControlsMask } from "../../_static/Masks";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import Info from "../../_molecule/Info";
import FundingMethod from "../../_organism/FundingMethod";

type Props = {
  isLoading: boolean,
  capiMessages: CapiMessages,
  dmaURL: string,
  device: Device,
  activeMethods: Method[],
  blockedMethods: Method[],
  setCurrentMethod: UnaryFn<string, void>,
  handleBlockButtonClick: BinaryFn<string, PaymentOptions, void>,
  setCurrentCode: UnaryFn<string, void>,
  paymentMethodNames: PaymentOptions,
  hasError: boolean,
  onErrorButtonCallback: NullaryFn<void>,
  handleAddMethodClick: UnaryFn<Event, void>
};

export const getMethodName = (
  paymentOptions: PaymentOptions,
  method: Method
) => {
  const keys = Object.keys(paymentOptions);
  return get(
    paymentOptions,
    keys.find((key) => get(method, "name") === key)
  );
};

const renderFundingMethods = (
  methods: Method[],
  paymentOptions: PaymentOptions,
  blockMessage: string,
  blockLiftedMessage: string,
  device: Device,
  handleBlockButtonClick?: BinaryFn<string, PaymentOptions, void>,
  setCurrentMethod?: UnaryFn<string, void>,
  setCurrentCode?: UnaryFn<string, void>
) => (
  <SeparatorBlock>
    {methods.map((method: Method) => (
      <Fragment key={`${method.name}`}>
        <FundingMethod
          name={getMethodName(paymentOptions, method)}
          blockButtonCallback={handleBlockButtonClick}
          blockMessage={blockMessage}
          blockLiftedMessage={blockLiftedMessage}
          setCurrentMethod={setCurrentMethod}
          setCurrentCode={setCurrentCode}
          code={method.name}
          isBlocked={get(method, "isBlocked")}
          unblockDate={get(method, "unblockDate")}
          device={device}
        />
        <Separator />
      </Fragment>
    ))}
  </SeparatorBlock>
);

const renderSupportMessage = (supportMessage: string, device: Device) => (
  <EmptyActiveMethodsSupportText
    data-qa-label="supportMessage"
    device={device}
    dangerouslySetInnerHTML={{ __html: supportMessage }}
  />
);

const renderActiveMethods = (
  methods: Method[],
  dmaURL: string,
  capiMessages: CapiMessages,
  handleBlockButtonClick: BinaryFn<string, PaymentOptions, void>,
  setCurrentMethod: UnaryFn<string, void>,
  setCurrentCode: UnaryFn<string, void>,
  paymentOptions: PaymentOptions,
  device: Device,
  handleAddMethodClick: UnaryFn<Event, void>
) =>
  methods.length ? (
    <SectionContainer data-qa-label="ActiveMethodsSection">
      <ContainerTitle data-qa-label="activeMethodsTitle" device={device}>
        {capiMessages.activeMethodsTitle}
      </ContainerTitle>
      {renderFundingMethods(
        methods,
        paymentOptions,
        capiMessages.blockMessage,
        capiMessages.blockLiftedMessage,
        device,
        handleBlockButtonClick,
        setCurrentMethod,
        setCurrentCode
      )}
    </SectionContainer>
  ) : (
    <SectionEmptyContainer data-qa-label="EmptyActiveMethodsSection">
      <Info
        messageType="info"
        title={get(
          capiMessages,
          "noActivePaymentsTitle",
          "No active payment methods"
        )}
        message={get(
          capiMessages,
          "noActivePaymentsMessage",
          "You have no active payment methods to make a deposit"
        )}
        mainIcon={device !== "mobile" ? plus : contests}
        full={device !== "mobile"}
      />
      <ButtonLink
        type="secondary"
        tag="a"
        size="big"
        isStretched
        isExternalLink
        url={dmaURL}
        qaLabel="addANewPaymentMethod"
        onClick={handleAddMethodClick}
      >
        {capiMessages.addNewPaymentMethod}
      </ButtonLink>
      {renderSupportMessage(get(capiMessages, "livePersonMessage", ""), device)}
    </SectionEmptyContainer>
  );

const renderBlockedMethods = (
  methods: Method[],
  paymentOptions: PaymentOptions,
  capiMessages: CapiMessages,
  device: Device
) =>
  methods.length ? (
    <Container
      data-qa-label="ActiveMethodsSection"
      hasMarginTop
      device={device}
    >
      <SectionContainer>
        <ContainerTitle data-qa-label="blockedMethodsTitle" device={device}>
          {capiMessages.blockedMethodsTitle}
        </ContainerTitle>
        {renderFundingMethods(
          methods,
          paymentOptions,
          capiMessages.blockMessage,
          capiMessages.blockLiftedMessage,
          device
        )}
      </SectionContainer>
    </Container>
  ) : null;

const FundingMethodsTemplate = (props: Props) => (
  <FundingMethodBackground device={props.device}>
    {props.hasError ? (
      <ErrorWrapper>
        <Info
          messageType="error"
          title={get(props.capiMessages, "componentError.title")}
          description={get(props.capiMessages, "componentError.description")}
          full
          hasButton
          buttonHandler={props.onErrorButtonCallback}
          qaLabel="FMRequestError"
        />
      </ErrorWrapper>
    ) : (
      <Fragment>
        <FundingMethodsHeader device={props.device}>
          {props.device === "desktop" && (
            <MoreInfo data-qa-label="moreInfoTitle">
              <FundingControlsTitle>
                {props.capiMessages.fundingControlsTitle}
              </FundingControlsTitle>
            </MoreInfo>
          )}
          <Description
            data-qa-label="FundingControls-TitleDescription"
            device={props.device}
          >
            {props.capiMessages.headerMessage}
          </Description>
        </FundingMethodsHeader>
        {props.isLoading ? (
          <Container device={props.device}>
            <FundingControlsMask />
          </Container>
        ) : (
          <FundingMethodsWrapper device={props.device}>
            <Container device={props.device}>
              {renderActiveMethods(
                props.activeMethods,
                props.dmaURL,
                props.capiMessages,
                props.handleBlockButtonClick,
                props.setCurrentMethod,
                props.setCurrentCode,
                props.paymentMethodNames,
                props.device,
                props.handleAddMethodClick
              )}
            </Container>
            {renderBlockedMethods(
              props.blockedMethods,
              props.paymentMethodNames,
              props.capiMessages,
              props.device
            )}
          </FundingMethodsWrapper>
        )}
      </Fragment>
    )}
  </FundingMethodBackground>
);

FundingMethodsTemplate.defaultProps = {
  isLoading: true,
  device: "mobile",
  capiMessages: {
    headerMessage: "",
    noActivePaymentsTitle: "",
    noActivePaymentsMessage: "",
    blockLiftedMessage: "",
    blockMessage: "",
    livePersonMessage: "",
    componentError: {
      title: "",
      description: "",
      buttonText: ""
    }
  },
  dmaURL: "/",
  activeMethods: [],
  blockedMethods: [],
  handleBlockButtonClick: noop,
  setCurrentMethod: noop,
  setCurrentCode: noop,
  paymentMethodNames: {
    ACH: "",
    CASH: "",
    CC: "",
    CHK: "",
    DEB: "",
    IBC: "",
    MPAK: "",
    PAY: "",
    PNM: "",
    PPAL: "",
    SLP: ""
  },
  hasError: false,
  onErrorButtonCallback: noop,
  handleAddMethodClick: noop
};

// $FlowFixMe
export default React.memo(FundingMethodsTemplate);
