import { addDays, addYears, format } from "date-fns";
import isRn from "./isRn";

const formatDateInDaysOrYears = (period, endDate, isDays) => {
  const newDate = isDays
    ? addDays(new Date(), period)
    : addYears(new Date(), period);
  return isRn ? format(newDate, "MM/dd/yyyy") : endDate;
};

export default formatDateInDaysOrYears;
