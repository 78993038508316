// @flow

import React, { Fragment } from "react";
import { noop } from "lodash";
import type { ListItemType, IconDescriptionType } from "@tvg/types/ListItem";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";

import ItemSelection from "../../_organism/RGItemSelection";
import IconsList from "../../_organism/IconsList";
import RGTimeoutWrapper, {
  MethodDescription,
  Divider,
  InputTitle,
  InputWrapper,
  Title,
  Header,
  Content
} from "./styled-components";
import Button from "../../_atom/Buttons/button";
import GenericInput from "../../_molecule/GenericInput";

export type SubmitInputValueType = {
  inputHeader: string,
  inputHeaderInvalid: string,
  inputPlaceholder: string,
  inputFooterInfo: string
};

type Props = {
  title: string,
  device: string,
  description: string,
  options: ListItemType[],
  optionsIdxSelected: number,
  optionsIdxSelectedUpdate: UnaryFn<number, void>,
  optionsSelectionTitle: string,
  optionsSelectionDescription: string,
  selfExcludeIdxSelected: number,
  selfExcludeIdxSelectedUpdate: UnaryFn<number, void>,
  selfExcludeSelectionTitle: string,
  selfExcludeSelectionDescription: string,
  isSelfExcludeOptionsShown: boolean,
  selfExcludeOptions: ListItemType[],
  iconsListTitle: string,
  iconsList: IconDescriptionType[],
  inputValue: string,
  isInputValueShown: boolean,
  isInputValueActive: boolean,
  isInputValueValid: boolean,
  handleInputChange: UnaryFn<Event, void>,
  inputValueCfg: SubmitInputValueType,
  submitButtonHandle: NullaryFn<void>,
  submitButtonText: string,
  canSubmit: boolean
};

const RGTimeout = ({
  title,
  description,
  options,
  optionsIdxSelected,
  optionsIdxSelectedUpdate,
  optionsSelectionTitle,
  optionsSelectionDescription,
  selfExcludeOptions,
  isSelfExcludeOptionsShown,
  selfExcludeIdxSelected,
  selfExcludeIdxSelectedUpdate,
  selfExcludeSelectionTitle,
  selfExcludeSelectionDescription,
  iconsListTitle,
  iconsList,
  isInputValueShown,
  inputValueCfg,
  submitButtonHandle,
  submitButtonText,
  canSubmit,
  isInputValueValid,
  inputValue,
  handleInputChange,
  device
}: Props) => (
  <RGTimeoutWrapper
    index={optionsIdxSelected}
    device={device}
    data-qa-label="rg-timeout"
  >
    <Header device={device} data-qa-label="rg-timeout-header">
      {device === "desktop" && (
        <Title data-qa-label="rg-timeout-header-title">{title}</Title>
      )}
      <MethodDescription data-qa-label="timeout&ExclusionsDescription">
        {description}
      </MethodDescription>
      {device === "mobile" && <Divider data-qa-label="rg-timeout-divider" />}
      <ItemSelection
        indexSelected={optionsIdxSelected}
        indexSelectedUpdate={optionsIdxSelectedUpdate}
        itemSelectionTitle={optionsSelectionTitle}
        itemSelectionDescription={optionsSelectionDescription}
        items={options}
        isSimplified={device !== "mobile"}
      />
      <Divider
        device={device}
        noSpaceBottom={device !== "mobile"}
        data-qa-label="rg-timeout-divider"
      />
    </Header>
    <Content device={device} data-qa-label="rg-timeout-content">
      <IconsList title={iconsListTitle} iconsList={iconsList} />
      <Divider data-qa-label="rg-timeout-divider" />
      {(isInputValueShown || isSelfExcludeOptionsShown) && (
        <Fragment>
          <InputWrapper data-qa-label="rg-timeout-input-wrapper">
            {isInputValueShown && (
              <Fragment>
                <InputTitle data-qa-label="rg-timeout-input-title">
                  {inputValueCfg.inputHeader}
                </InputTitle>
                <GenericInput
                  qaLabel="rg-timeout"
                  size={device === "desktop" ? "large" : "normal"}
                  opacityPlaceholder
                  showDollarSign={false}
                  isValid={isInputValueValid}
                  value={inputValue}
                  type="tel"
                  maxLength={3}
                  placeholder={inputValueCfg.inputPlaceholder}
                  errorMsg={inputValueCfg.inputHeaderInvalid}
                  onChangeHandler={handleInputChange}
                />
              </Fragment>
            )}
            {isSelfExcludeOptionsShown && (
              <ItemSelection
                indexSelected={selfExcludeIdxSelected}
                indexSelectedUpdate={selfExcludeIdxSelectedUpdate}
                itemSelectionTitle={selfExcludeSelectionTitle}
                itemSelectionDescription={selfExcludeSelectionDescription}
                items={selfExcludeOptions}
              />
            )}
          </InputWrapper>
        </Fragment>
      )}

      <Button
        qaLabel="rg-timeout-button"
        type="primary"
        size="big"
        isStretched
        isDisabled={!canSubmit}
        onClick={submitButtonHandle}
        optionalData="openRGModal"
      >
        {submitButtonText}
      </Button>
    </Content>
  </RGTimeoutWrapper>
);

RGTimeout.defaultProps = {
  title: "",
  device: "mobile",
  description: "",
  options: [
    {
      id: "timeout",
      title: "",
      description: "",
      isNew: false,
      icon: "timer"
    },
    {
      id: "selfExclude",
      title: "",
      description: "",
      isNew: false,
      icon: "calendar"
    },
    {
      id: "suspend",
      title: "",
      description: "",
      isNew: false,
      icon: "lock"
    }
  ],
  optionsIdxSelected: 0,
  optionsIdxSelectedUpdate: noop,
  optionsSelectionTitle: "",
  optionsSelectionDescription: "",
  selfExcludeIdxSelected: 0,
  selfExcludeIdxSelectedUpdate: noop,
  selfExcludeSelectionTitle: "",
  selfExcludeSelectionDescription: "",
  isSelfExcludeOptionsShown: false,
  selfExcludeOptions: [
    {
      id: "1year",
      title: "1",
      description: "",
      isNew: false
    },
    {
      id: "3year",
      title: "3",
      description: "",
      isNew: false
    },
    {
      id: "5year",
      title: "5",
      description: "",
      isNew: false
    }
  ],
  iconsListTitle: "",
  iconsList: [
    {
      id: "1",
      icon: "lock",
      description: ""
    },
    {
      id: "2",
      icon: "refresh",
      description: ""
    },
    {
      id: "3",
      icon: "alertSuspended",
      description: ""
    }
  ],
  inputValue: "",
  isInputValueShown: false,
  isInputValueActive: true,
  isInputValueValid: true,
  handleInputChange: noop,
  inputValueCfg: {
    inputHeader: "",
    inputHeaderInvalid: "",
    inputPlaceholder: "",
    inputFooterInfo: ""
  },
  submitButtonHandle: noop,
  submitButtonText: "",
  canSubmit: false
};

export default RGTimeout;
