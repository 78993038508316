// @flow

import styled from "styled-components";

const ItemGroupContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;

  > button {
    margin: 0 4px;
  }

  > :first-child {
    margin-left: 0;
  }

  > :last-child {
    margin-right: 0;
  }
`;

export default ItemGroupContainer;
