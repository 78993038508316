// @flow

import React, { PureComponent, Fragment, type Node } from "react";
import { connect } from "react-redux";
import { type Location } from "react-router-dom";
import { type Dispatch } from "redux";
import { noop, get } from "lodash";
import styled from "styled-components";
import DOMPurify from "dompurify";
import type { Device } from "@tvg/types/Device";
import type { UnaryFn } from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import {
  titleFont,
  defaultTextFont,
  defaultSize
} from "@tvg/atomic-ui/_static/Typography";
import ResponsibleGaming from "@tvg/atomic-ui/_templates/ResponsibleGaming";
import ResponsibleGamingSideMenu from "@tvg/atomic-ui/_templates/ResponsibleGamingSideMenu";
import { parseCAPIMessage, replaceCAPIVariables } from "@tvg/utils/capiUtils";
import { generateLinkString } from "@tvg/utils/mediatorUtils";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { getAccountNumber } from "@urp/store-selectors";

export const RG_WAGER_RESPONSIBLY_LABEL = "AWARE";
export const RG_WAGER_RESPONSIBLY_PATH = {
  desktop: "/info/wagerresponsibly",
  mobile: "/responsible-gaming#wagerResponsibly",
  tablet: "/responsible-gaming#wagerResponsibly"
};

type Props = {
  device: Device,
  dispatch: Dispatch<*>,
  location: Location,
  accountNumber: string,
  rgHomePage: {
    homeTitle: string,
    userLimitsLabel: string,
    homeDescription: string,
    userLimits: ListItemType[],
    userLimitsDesc: string,
    timeOutExclusionsLabel: string,
    timeOutExclusionsDesc: string,
    timeOutExclusions: ListItemType[],
    awareProgramTitle: string,
    awareProgram: string,
    qrCodeInfo: string
  },
  wagerResponsiblyMessage: string,
  optionSelected: string | null,
  optionSelectedHandler: UnaryFn<string, mixed>,
  children?: Node
};

type State = {
  isModalOpen: boolean
};

const InfoModalSection = styled.section`
  background-color: ${buildColor("white", "100")};
  padding: 16px 8px;
  overflow-x: hidden;
  word-break: break-word;

  strong,
  h3,
  b {
    color: ${buildColor("blue", "500")};
    font-family: ${titleFont};
    font-size: ${defaultSize};
    font-weight: bold;
    font-stretch: condensed;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
  }

  p {
    font-family: ${defaultTextFont};
    font-size: ${defaultSize};
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${buildColor("grey", "800")};
    margin: 15px 0;
  }

  a {
    text-decoration: none;
  }
`;

const QrContainer = styled.div`
  margin-top: 20px;
  display: flex;

  img {
    max-width: 100px;
    margin-right: 10px;
  }
`;

export const renderWagerResponsibly = (
  awareProgram: string,
  qrCodeInfo: string,
  device: Device,
  changeModalState?: UnaryFn<boolean, void>
) => {
  if (typeof window !== "undefined" && tvgConf().product === "iosnative") {
    window.handleAwareClick = (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (changeModalState) {
        changeModalState(true);
      }
    };
  }
  const awareCallback = "window.handleAwareClick(event)";
  const { NCPGamblingChat } = tvgConf().getExternalDomain();
  const NCPGamblingChatLabel = (NCPGamblingChat || "").replace(
    /https?:\/\//,
    ""
  );
  const awareLinks = {
    AWARE: generateLinkString(
      RG_WAGER_RESPONSIBLY_PATH[device],
      RG_WAGER_RESPONSIBLY_LABEL,
      tvgConf().product === "iosnative" ? awareCallback : "undefined"
    ),
    NCPGamblingChat: generateLinkString(
      NCPGamblingChat,
      NCPGamblingChatLabel,
      awareCallback
    )
  };

  const awareProgramWithLinks = replaceCAPIVariables(awareProgram, awareLinks);

  return (
    <React.Fragment>
      <span
        dangerouslySetInnerHTML={{
          __html:
            typeof window !== "undefined"
              ? DOMPurify.sanitize(awareProgramWithLinks, {
                  ADD_ATTR: ["target", "onclick"]
                })
              : ""
        }}
      />
      <QrContainer>
        <img
          src="/static/image/qr_code_ny_rg.png"
          alt="QR Code NY Responsible Gaming"
        />
        <span
          dangerouslySetInnerHTML={{
            __html:
              typeof window !== "undefined"
                ? DOMPurify.sanitize(qrCodeInfo, {
                    ADD_ATTR: ["target", "onclick"]
                  })
                : ""
          }}
        />
      </QrContainer>
    </React.Fragment>
  );
};

export class ResponsibleGamingComponent extends PureComponent<Props, State> {
  static defaultProps = {
    device: "mobile",
    accountNumber: "",
    location: {
      pathname: "",
      search: "",
      hash: "",
      state: {},
      key: ""
    },
    rgHomePage: {
      homeTitle: "",
      homeDescription: "",
      userLimitsLabel: "",
      userLimitsDesc: "",
      userLimits: [],
      timeOutExclusionsLabel: "",
      timeOutExclusionsDesc: "",
      timeOutExclusions: [],
      awareProgramTitle: "",
      awareProgram: "",
      qrCodeInfo: ""
    },
    wagerResponsiblyMessage: "",
    optionSelected: null,
    optionSelectedHandler: noop,
    children: null
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  componentDidMount() {
    const { pathname, hash } = this.props.location;
    mediator.base.dispatch({
      type: "RESPONSIBLE-GAMING:NAVIGATE",
      payload: {
        destination: `${pathname}${hash}`
      }
    });
  }

  buttonClickHandler = (
    gaEventLabel: string,
    destinationUrl: ?string
  ): void => {
    mediator.base.dispatch({
      type: "RG_HOME_CLICK",
      payload: {
        gaEventLabel,
        destinationUrl: `/${destinationUrl || ""}`
      }
    });
  };

  optionSelectedHandler = (rgOption: string): void => {
    mediator.base.dispatch({
      type: "RG_OPTION_SELECT_HANDLE",
      payload: {
        module: rgOption
      }
    });
    this.props.optionSelectedHandler(rgOption);
  };

  changeModalState = (value: boolean) => {
    this.setState({
      isModalOpen: value
    });
  };

  render() {
    const {
      homeTitle,
      homeDescription,
      userLimitsLabel,
      userLimitsDesc,
      userLimits,
      timeOutExclusionsLabel,
      timeOutExclusionsDesc,
      timeOutExclusions,
      awareProgramTitle,
      awareProgram,
      qrCodeInfo
    } = this.props.rgHomePage;

    return userLimits || timeOutExclusions ? (
      <Fragment>
        {this.props.device === "desktop" && this.props.children ? (
          <ResponsibleGamingSideMenu
            title={homeTitle}
            description={homeDescription}
            userLimitsOptionsTitle={userLimitsLabel}
            userLimitsOptionsDesc={userLimitsDesc}
            userLimitsOptions={userLimits}
            timeOutExclusionsTitle={timeOutExclusionsLabel}
            timeOutExclusionsDesc={timeOutExclusionsDesc}
            timeOutExclusions={timeOutExclusions}
            buttonClickHandler={this.buttonClickHandler}
            WagerResponsiblyTitle={awareProgramTitle}
            WagerResponsibly={renderWagerResponsibly(
              awareProgram,
              qrCodeInfo,
              this.props.device
            )}
            optionSelected={this.props.optionSelected}
            optionSelectedHandler={this.optionSelectedHandler}
          >
            {this.props.children}
          </ResponsibleGamingSideMenu>
        ) : (
          <Fragment>
            <ResponsibleGaming
              device={this.props.device}
              description={homeDescription}
              userLimitsOptions={userLimits}
              userLimitsOptionsTitle={userLimitsLabel}
              userLimitsOptionsDesc={userLimitsDesc}
              timeOutExclusions={timeOutExclusions}
              timeOutExclusionsTitle={timeOutExclusionsLabel}
              timeOutExclusionsDesc={timeOutExclusionsDesc}
              buttonClickHandler={this.buttonClickHandler}
              WagerResponsiblyTitle={awareProgramTitle}
              WagerResponsibly={renderWagerResponsibly(
                awareProgram,
                qrCodeInfo,
                this.props.device,
                this.changeModalState
              )}
            />
            {tvgConf().product === "iosnative" && (
              <ModalV2
                title="Wager Responsibly"
                isFullHeight
                animation="right"
                isOpen={this.state.isModalOpen}
                onClose={() => {
                  this.changeModalState(false);
                }}
                titleType="navigation"
                offsetTop={0}
                hasCloseButton={false}
                hasOverlay={false}
              >
                {() => (
                  <InfoModalSection
                    dangerouslySetInnerHTML={{
                      __html: this.props.wagerResponsiblyMessage
                    }}
                  />
                )}
              </ModalV2>
            )}
          </Fragment>
        )}
      </Fragment>
    ) : null;
  }
}

export default connect(
  (store) => ({
    rgHomePage: parseCAPIMessage(store, "capi.messages.rgHomePage", {
      homeTitle: "",
      homeDescription: "",
      userLimitsLabel: "",
      userLimitsDesc: "",
      userLimits: [],
      timeOutExclusionsLabel: "",
      timeOutExclusionsDesc: "",
      timeOutExclusions: [],
      awareProgramTitle: "",
      awareProgram: ""
    }),
    wagerResponsiblyMessage: get(store, "capi.messages.wagerResponsibly", ""),
    accountNumber: getAccountNumber(store)
  }),
  (dispatch: Dispatch<*>) => ({ dispatch })
)(ResponsibleGamingComponent);
