// @flow
import * as React from "react";
import { noop } from "lodash";
import type { UnaryFn } from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";
import ListOptionGroup, {
  type OptionGroup,
  type Option
} from "../../_organism/ListOptionGroup";

import {
  RGContainer,
  RGHeader,
  RGHeaderContent,
  RGTitle,
  RGDescription,
  RGMain,
  RGNavbar,
  RGContent,
  ContainerFooter,
  FooterTitle
} from "./styled-components";

type Props = {
  qaLabel: string,
  title: string,
  description: string,
  userLimitsOptionsTitle: string,
  userLimitsOptions: ListItemType[],
  timeOutExclusionsTitle: string,
  timeOutExclusions: ListItemType[],
  optionSelected: string | null,
  optionSelectedHandler: UnaryFn<string, mixed>,
  WagerResponsiblyTitle: string,
  WagerResponsibly: React$Element<*>,
  children: React.Node | null
};

const listItemToOptions = (list: ListItemType[]): Option[] =>
  list.map((item) => ({
    id: item.id,
    title: item.title
  }));

const buildListOptionGroup = (
  userLimitsOptionsTitle: string,
  userLimitsOptions: ListItemType[],
  timeOutExclusionsTitle: string,
  timeOutExclusions: ListItemType[]
) => {
  const userLimitsOptionGroup: OptionGroup = {
    groupId: 1,
    groupTitle: userLimitsOptionsTitle,
    options: listItemToOptions(userLimitsOptions)
  };

  const timeoutExclusionsOptionGroup: OptionGroup = {
    groupId: 2,
    groupTitle: timeOutExclusionsTitle,
    options: listItemToOptions(timeOutExclusions)
  };

  return [userLimitsOptionGroup, timeoutExclusionsOptionGroup];
};

export default function ResponsibleGamingSideMenu({
  qaLabel,
  title,
  description,
  userLimitsOptionsTitle,
  userLimitsOptions,
  timeOutExclusionsTitle,
  timeOutExclusions,
  optionSelected,
  optionSelectedHandler,
  WagerResponsiblyTitle,
  WagerResponsibly,
  children
}: Props) {
  return (
    <RGContainer data-qa-label={qaLabel}>
      <RGHeader>
        <RGHeaderContent>
          <RGTitle data-qa-label={`${qaLabel}-title`}>{title}</RGTitle>
          <RGDescription data-qa-label={`${qaLabel}-description`}>
            {description}
          </RGDescription>
        </RGHeaderContent>
      </RGHeader>
      <RGMain>
        <RGNavbar data-qa-label={`${qaLabel}-navbar`}>
          <ListOptionGroup
            listOptionGroup={buildListOptionGroup(
              userLimitsOptionsTitle,
              userLimitsOptions,
              timeOutExclusionsTitle,
              timeOutExclusions
            )}
            optionSelected={optionSelected}
            optionSelectedHandler={optionSelectedHandler}
          />
        </RGNavbar>
        <RGContent data-qa-label={`${qaLabel}-content`}>
          {children}
          <ContainerFooter>
            <FooterTitle>{WagerResponsiblyTitle}</FooterTitle>
            {WagerResponsibly}
          </ContainerFooter>
        </RGContent>
      </RGMain>
    </RGContainer>
  );
}

ResponsibleGamingSideMenu.defaultProps = {
  qaLabel: "RGTemplate",
  title: "",
  description: "",
  userLimitsOptionsTitle: "",
  userLimitsOptions: [],
  timeOutExclusionsTitle: "",
  timeOutExclusions: [],
  optionSelected: null,
  optionSelectedHandler: noop,
  WagerResponsiblyTitle: "",
  WagerResponsibly: <React.Fragment />,
  children: null
};
