// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, defaultSize } from "../../_static/Typography";

export const TitleContainer = styled.h3`
  margin-top: 18px;
  font-size: ${defaultSize};
  font-family: ${fontBold};
  color: ${buildColor("grey", "900")};
  letter-spacing: 0;
`;

export const InputWrapper = styled.div`
  margin: 12px 0;
`;

export const UnderlineInfo = styled.p`
  margin-top: 16px;
  color: ${buildColor("grey", "600")};
  font-size: 12px;
  letter-spacing: 0;
  text-align: center;
  font-family: ${fontNormal};
  white-space: pre-line;
`;
