// @flow
import styled from "styled-components";
import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export default styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;

  &:last-of-type {
    margin-top: 8px;
  }
`;

export const IconListTitle = styled.h4`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
`;

export const IconsListElement = styled.li`
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const IconsListDescription = styled.p`
  margin-bottom: 12px;
`;
