// @flow
import { combineReducers } from "redux";

import capiReducer, {
  type State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  type State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

import appReducer, {
  type State as AppState,
  initialState as initialAppState
} from "./App/appReducer";

import modalReducer, {
  type State as ModalState,
  initialState as initialModalState
} from "./App/modalReducer";

export type State = {
  capi: CapiState,
  userData: UserState,
  app: AppState,
  modal: ModalState
};

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  app: initialAppState,
  modal: initialModalState
};

export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  app: appReducer,
  modal: modalReducer
});
