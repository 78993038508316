// @flow
import React from "react";
import { get, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { UnaryFn, BinaryFn } from "@tvg/types/Functional";
import type { PaymentOptionsEnum } from "@tvg/types/ResponsibleGaming";
import { formatDateToMMDDYYYYSlashhhmm } from "@tvg/formatter/dates";
import { infoOutline } from "../../_static/Icons/icons";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import {
  Container,
  MethodName,
  MessageContainer,
  LeftMessageWrapper
} from "./styled-components";
import Button from "../../_atom/Buttons/button";
import Text from "../../_atom/Text";

type Blocked = {
  isBlocked: boolean,
  name: string,
  unblockDate: Date,
  code: PaymentOptionsEnum
};

type Props = Blocked & {
  blockButtonCallback: BinaryFn<string, PaymentOptionsEnum, void>,
  setCurrentMethod: UnaryFn<string, void>,
  setCurrentCode: UnaryFn<string, void>,
  device: Device,
  blockLiftedMessage: string,
  blockMessage: string
};

const blockButton = (
  callback: UnaryFn<boolean, void>,
  blockMessage: string
) => (
  <Button
    qaLabel="block-button"
    type="secondary"
    size="default"
    onClick={() => callback(true)}
    optionalData="openRGModal"
  >
    {blockMessage}
  </Button>
);

const renderUnblockDate = (
  date: string,
  blockLiftedMessage: string,
  device: Device
) =>
  date && (
    <MessageContainer device={device}>
      <LeftMessageWrapper device={device}>
        <Icon
          icon={infoOutline}
          size={10}
          qaLabel="InfoIcon"
          color={buildColor("orange", "500")}
        />
        <Text color={buildColor("orange", "500")}>{blockLiftedMessage}</Text>
      </LeftMessageWrapper>
      <Text color={buildColor("orange", "500")}>
        {formatDateToMMDDYYYYSlashhhmm(date)}
      </Text>
    </MessageContainer>
  );

const onBlockButtonClick = ({
  name,
  blockButtonCallback,
  setCurrentMethod,
  setCurrentCode,
  code
}: Props) => {
  setCurrentMethod(name);
  setCurrentCode(code);
  blockButtonCallback(name, code);
};

export const genQaLabel = (name: string, blocked: boolean): string =>
  `rg-${blocked ? "blocked" : "block"}-row-${name
    .toLowerCase()
    .trim()
    .replace(/ /g, "-")}`;

const FundingMethod = (props: Props) => (
  <Container
    hasMessage={get(props, "isBlocked", false)}
    device={props.device}
    data-qa-label={genQaLabel(
      get(props, "name", ""),
      get(props, "isBlocked", false)
    )}
  >
    <MethodName device={props.device}>{get(props, "name", "")}</MethodName>
    {get(props, "isBlocked", false)
      ? renderUnblockDate(
          get(props, "unblockDate", ""),
          props.blockLiftedMessage,
          props.device
        )
      : blockButton(() => onBlockButtonClick(props), props.blockMessage)}
  </Container>
);

FundingMethod.defaultProps = {
  isBlocked: false,
  name: "",
  device: "mobile",
  blockLiftedMessage: "",
  blockMessage: "",
  unblockDate: null,
  blockButtonCallback: noop,
  setCurrentMethod: noop,
  setCurrentCode: noop
};

// $FlowFixMe
export default React.memo(FundingMethod);
