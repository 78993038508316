// @flow

import styled from "styled-components";
import { buildText } from "../../_atom/Text";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium, defaultSize } from "../../_static/Typography";

export const InputDollarSign = styled.span`
  padding-left: 12px;
  align-self: center;
  font-size: ${defaultSize};
  font-family: ${fontMedium};
  font-weight: 500;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${({ size }) => {
    switch (size) {
      case "large":
        return "52px";
      default:
        return "40px";
    }
  }};
  background: ${buildColor("white", "100")};
  border: 1px solid
    ${({ opacityPlaceholder }) =>
      opacityPlaceholder
        ? buildColor("blue", "100")
        : buildColor("grey", "500")};
  border-radius: 2px;
  ${(props) =>
    !props.isValid &&
    `
    border: 1px solid ${buildColor("red", "500")};
    border-bottom-left-radius: 0; 
    border-bottom-right-radius: 0;`};

  &:focus-within {
    border: solid 1px ${buildColor("blue_accent", "500")};
  }

  &:focus-within ${InputDollarSign} {
    color: ${buildColor("blue_accent", "500")};
  }
`;

export const InputField = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 ${({ showDollarSign }) => (showDollarSign ? "4px" : "12px")};
  align-self: center;
  appearance: none;
  border: 0;
  font-size: ${defaultSize};
  font-family: ${fontMedium};
  font-weight: 500;
  letter-spacing: 0;
  ${(props) => !props.isValid && `color: ${buildColor("red", "500")}`};

  &::placeholder {
    color: ${(props) =>
      props.opacityPlaceholder
        ? buildColor("blue", "100")
        : buildColor("grey", "800")};
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:focus {
    color: ${buildColor("blue_accent", "500")};
  }
`;

export const InputIconWrapper = styled.span`
  padding-right: 12px;
  align-self: center;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  padding: 8px 12px;
  color: ${buildColor("red", "500")};
  background: ${buildColor("red", "000")};
  border-style: solid;
  border-width: 0 1px 1px;
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: normal;
  letter-spacing: 0;
`;

export const InputContainer = styled.fieldset`
  position: relative;
  margin-bottom: 16px;
`;

export const InputLabel = styled(
  buildText({
    tag: "label",
    fontSize: 12,
    bold: true,
    color: buildColor("grey", "900")
  })
)`
  display: block;
  position: relative;
  margin-bottom: 4px;
`;
