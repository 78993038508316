import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, defaultSize, fontBold } from "../../_static/Typography";

export default styled.div`
  min-height: 210px;
  ${({ device }) =>
    device === "tablet" &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
  ${({ device }) =>
    device === "mobile" &&
    css`
      background-color: ${buildColor("white", "100")};
      padding: 4px 12px 12px;
      border-bottom: 1px solid ${buildColor("blue", "100")};
      border-radius: 0;
      box-shadow: 0 1px00 rgb(214, 227, 240);
    `}
  ${({ device }) =>
    device === "desktop" &&
    css`
      background-color: ${buildColor("white", "100")};
      padding: 0;
      border-radius: 2px;
      border: 1px solid ${buildColor("blue", "100")};
      box-shadow: 0 4px 8px0 rgba(147, 175, 205, 0.03),
        0 1px 3px0 rgba(99, 119, 140, 0.04);
    `}
`;

export const Divider = styled.div`
  margin: 0;
  margin-top: ${({ noSpaceBottom }) => (noSpaceBottom ? 0 : "16px")};
  margin-bottom: ${({ device }) => (device === "tablet" ? 0 : "16px")};
  width: calc(100% + 16px);
  height: 1px;
  border-top: solid 1px ${buildColor("blue", "100")};
`;

export const MethodDescription = styled.p`
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  width: 100%;
  line-height: 18px;
  white-space: pre-line;
`;

export const InputTitle = styled.div`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: ${defaultSize};
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 3px;
  margin-bottom: 12px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 12px;
`;

export const Title = styled.h1`
  margin-bottom: 8px;
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
`;

export const Header = styled.div`
  background-color: ${buildColor("white", "100")};
  ${({ device }) => css`
    ${device === "desktop" && "padding: 16px 16px 0;"}
    ${device === "mobile" && "width: 100%;"}
    ${device === "tablet" &&
    `
      width: 100%;
      padding: 4px 37px 0;
      margin-bottom: 12px;
    `}
  `}
`;

export const Content = styled.div`
  background-color: ${buildColor("white", "100")};
  ${({ device }) =>
    device === "mobile" &&
    css`
      width: 100%;
    `}
  ${({ device }) =>
    device === "tablet" &&
    css`
      max-width: 635px;
      width: 100%;
      padding: 16px;
      margin: 0 12px;
      border-radius: 2px;
      border: 1px solid ${buildColor("blue", "100")};
    `}
  ${({ device }) =>
    device === "desktop" &&
    css`
      padding: 0 16px 16px;
    `}
`;
