const Routes = [
  {
    key: "rgSelfExclude",
    path: "/responsible-gaming/self-exclude",
    exact: true
  },
  {
    key: "rgSuspendAccount",
    path: "/responsible-gaming/suspend-account",
    exact: true
  },
  {
    key: "rgTimeout",
    path: "/responsible-gaming/timeout",
    exact: true
  }
];

export default Routes;
