// @flow

export type uwtPaymentOption = {
  accoundId: number,
  paymentType: string,
  minLimit: number,
  maxLimit: number,
  releaseDate: string,
  isDepositAvailable: boolean,
  mostRecentDeposit: ?string,
  waivedFees: number,
  depositExclusionType: number,
  isWithdrawalAvailable: boolean,
  mostRecentWithdrawal: ?string,
  depositRemainingWaivedAmount: number,
  depositWaivedFeeCeiling: number,
  depositFeeWaivedExpirationDate: ?string,
  responsibleIsBlocked: boolean,
  responsibleBlockDate: ?string,
  responsibleUnblockDate: ?string
};

export type PaymentOptions = {
  ACH: string,
  CASH: string,
  CC: string,
  CHK: string,
  DEB: string,
  IBC: string,
  MPAK: string,
  PAY: string,
  APPL: string,
  PNM: string,
  PPAL: string,
  SLP: string,
  MZM: string
};

export type PaymentOptionsEnum =
  | "ACH"
  | "CASH"
  | "CC"
  | "CHK"
  | "DEB"
  | "IBC"
  | "MPAK"
  | "PAY"
  | "PNM"
  | "PPAL"
  | "MZM"
  | "SLP";

export type Method = {
  isBlocked: boolean,
  name: string,
  unblockDate: ?string
};

export type CapiMessages = {
  headerMessage: string,
  activeMethodsTitle: string,
  blockedMethodsTitle: string,
  fundingControlsTitle: string,
  blockLiftedMessage: string,
  blockMessage: string,
  addNewPaymentMethod: string,
  noActivePaymentsTitle: string,
  noActivePaymentsMessage: string,
  fundingControlsTitle: string,
  livePersonMessage: string,
  componentError: {
    title: string,
    subTitle: string,
    buttonText: string
  }
};

export type LimitsKeys = "dailyLimit" | "monthlyLimit" | "weeklyLimit";

export type Limits = {
  [LimitsKeys]: number
};

export type MODAL_STATUS_TYPE = "" | "CONFIRM" | "SUCCESS" | "ERROR";

const RG_OPTIONS = {
  DEPOSIT_LIMIT: "DEPOSIT_LIMIT",
  FUNDING_CONTROLS: "FUNDING_CONTROLS",
  TIMEOUT: "TIMEOUT",
  SELF_EXCLUSION: "SELF_EXCLUSION",
  SUSPEND: "SUSPEND"
};

export const RG_TIMEOUT_EVENTS_MAPPING = {
  TIMEOUT: "SHOW_RG_TIMEOUT_SCREEN",
  SELF_EXCLUSION: "SHOW_RG_SELF_EXCLUSION_SCREEN",
  SUSPEND: "SHOW_RG_SUSPEND_SCREEN"
};

export default RG_OPTIONS;
