// @flow
import React from "react";
import { get, noop } from "lodash";
import type { Device } from "@tvg/types/Device";
import type { BinaryFn } from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";
import ListItems from "../../_organism/ListItems";

import ResponsibleGamingContainer, {
  ContainerDescription,
  Description,
  ContainerListItems,
  ContainerFooter,
  FooterTitle
} from "./styled-components";

type Props = {
  device: Device,
  description: string,
  userLimitsOptions: ListItemType[],
  userLimitsOptionsTitle: string,
  userLimitsOptionsDesc: string,
  timeOutExclusions: ListItemType[],
  timeOutExclusionsTitle: string,
  timeOutExclusionsDesc: string,
  buttonClickHandler: BinaryFn<string, ?string, void>,
  WagerResponsiblyTitle: string,
  WagerResponsibly: React$Element<*>
};

const ResponsibleGaming = (props: Props) => {
  const {
    device,
    description,
    userLimitsOptions,
    userLimitsOptionsTitle,
    userLimitsOptionsDesc,
    timeOutExclusions,
    timeOutExclusionsTitle,
    timeOutExclusionsDesc,
    buttonClickHandler,
    WagerResponsiblyTitle,
    WagerResponsibly
  } = props;

  return (
    <ResponsibleGamingContainer isTablet={device === "tablet"}>
      <ContainerDescription data-qa-label="RG_Header_Description">
        <Description>{description}</Description>
      </ContainerDescription>
      <ContainerListItems device={device}>
        {get(userLimitsOptions, "length") > 0 && (
          <ListItems
            title={userLimitsOptionsTitle}
            description={userLimitsOptionsDesc}
            listItems={userLimitsOptions}
            buttonClickHandler={buttonClickHandler}
          />
        )}
      </ContainerListItems>
      <ContainerListItems device={device}>
        {get(timeOutExclusions, "length") > 0 && (
          <ListItems
            title={timeOutExclusionsTitle}
            description={timeOutExclusionsDesc}
            listItems={timeOutExclusions}
            buttonClickHandler={buttonClickHandler}
          />
        )}
      </ContainerListItems>
      <ContainerFooter>
        <FooterTitle>{WagerResponsiblyTitle}</FooterTitle>
        {WagerResponsibly}
      </ContainerFooter>
    </ResponsibleGamingContainer>
  );
};

ResponsibleGaming.defaultProps = {
  device: "mobile",
  description: "",
  userLimitsOptions: [],
  userLimitsOptionsTitle: "",
  userLimitsOptionsDesc: "",
  timeOutExclusions: [],
  timeOutExclusionsTitle: "",
  timeOutExclusionsDesc: "",
  buttonClickHandler: noop,
  WagerResponsiblyTitle: "",
  WagerResponsibly: ""
};

export default ResponsibleGaming;
