// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

export const Container = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: ${(props) =>
    !props.hasMessage || props.device === "desktop" ? "row" : "column"};
  justify-content: ${(props) =>
    props.device === "desktop" ? "space-between" : "flex-start"};
  align-items: ${(props) => (props.hasMessage ? "flex-start" : "center")};
  padding: ${(props) => {
    const sidePadding = props.device === "mobile" ? "12px" : "16px";
    return props.hasMessage ? `16px ${sidePadding} 12px` : `0 ${sidePadding}`;
  }};
`;

export const MethodName = styled.span`
  flex-grow: 1;
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: ${buildColor("grey", "900")};
  white-space: ${({ device }) => (device === "desktop" ? "nowrap" : "normal")};
`;

export const MessageContainer = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.device === "desktop" ? "0" : "12px")};
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.device === "desktop" ? "flex-end" : "space-between"};
`;

export const LeftMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.device === "desktop" ? "8px" : "0")};

  & > span {
    margin-left: 8px;
  }
`;
