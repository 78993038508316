// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium, defaultSize } from "../../_static/Typography";

export const ItemWrapper = styled.button`
  ${({ isSmaller }) =>
    isSmaller
      ? css`
          padding: 8px 12px;
          outline: none;
          border-bottom: 2px solid
            ${({ isActive }) =>
              isActive ? buildColor("blue_accent", "500") : "transparent"};
          background-color: transparent;
        `
      : css`
          ${({ hasIcon }) =>
            hasIcon
              ? css`
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  flex-basis: 100%;
                  height: 115px;
                  align-items: center;
                `
              : css`
                  flex: 1 1 auto;
                `};
          padding: 12px;
          border-radius: 4px;
          border: 1px solid
            ${({ isActive }) =>
              isActive
                ? buildColor("blue_accent", "500")
                : buildColor("blue", "100")};
          outline: none;
          background-color: ${({ isActive }) =>
            isActive
              ? buildColor("blue_accent", "100")
              : buildColor("blue_accent", "000")};
          box-shadow: 0 1px 2px 0
            ${({ isActive }) =>
              isActive ? "rgba(83, 144, 237, 0.2)" : buildColor("blue", "000")};
        `}
`;

export const IconWrapper = styled.span`
  background-color: ${({ isActive }) =>
    isActive
      ? buildColor("blue_accent", "200")
      : buildColor("blue_accent", "100")};
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const SmallIconWrapper = styled(IconWrapper)`
  background-color: ${buildColor("blue_accent", "500")};
  height: 16px;
  width: 16px;
`;

export const ItemMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemMainTitle = styled.div`
  text-align: left;
  color: ${({ isActive }) =>
    isActive ? buildColor("blue_accent", "500") : buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: ${({ hasIcon }) => (hasIcon ? defaultSize : "16px")};
  font-weight: 500;
  word-wrap: break-word;
  letter-spacing: 0;
`;

export const ItemDescription = styled.div`
  text-align: left;
  color: ${({ isActive }) =>
    isActive ? buildColor("blue_accent", "500") : buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: normal;
  letter-spacing: 0;
`;
