// @flow
import mediator from "@tvg/mediator";
import type { NullaryFn } from "@tvg/types/Functional";

export type RgModalActions = {
  type:
    | "RG_MODAL_DEPOSIT_LIMIT_OPEN"
    | "RG_MODAL_FUNDING_CONTROL_OPEN"
    | "RG_MODAL_TIMEOUT_OPEN"
    | "RG_MODAL_SUBMIT"
    | "RG_MODAL_CANCEL"
    | "RG_MODAL_CLOSE"
    | "RG_MODAL_SELF_EXCLUSION_OPEN"
    | "RG_MODAL_SUSPENSION_OPEN"
    | "RG_MODAL_ERROR"
    | "GET_RG_MODAL_CALLBACK"
    | "GET_RG_MODAL_CALLBACK_SECONDARY"
    | "RG_MODAL_CLEAR_CALLBACKS"
    | "RG_MODAL_PROCESSING"
    | "RG_MODAL_FINISHED",
  payload?: {
    newLimit?: string,
    currentLimit?: string,
    appliedDate?: string,
    fundingMethod?: string,
    start?: string,
    end?: string
  }
};

export const rgSetCallback = (callback: NullaryFn<void>) => ({
  type: "GET_RG_MODAL_CALLBACK",
  payload: {
    callback
  }
});

const setCallback = (callback: NullaryFn<void>) => {
  if (typeof callback === "function") {
    mediator.base.dispatch(rgSetCallback(callback));
  }
};

export const rgSetCallbackSecondary = (callback: NullaryFn<void>) => ({
  type: "GET_RG_MODAL_CALLBACK_SECONDARY",
  payload: {
    callback
  }
});

const setCallbackSecondary = (callback: NullaryFn<void>) => {
  if (typeof callback === "function") {
    mediator.base.dispatch(rgSetCallbackSecondary(callback));
  }
};

export const rgClearCallbacks = () => ({
  type: "RG_MODAL_CLEAR_CALLBACKS"
});

const clearCallbacks = () => {
  mediator.base.dispatch(rgClearCallbacks());
};

export const rgDepositLimitModalOpen = (
  newLimit: string,
  appliedDate?: string,
  currentLimit?: string,
  primaryCallback: NullaryFn<void>,
  secondaryCallback: NullaryFn<void>
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_DEPOSIT_LIMIT_OPEN",
    payload: {
      newLimit,
      currentLimit,
      appliedDate
    }
  };
};

export const rgFundingControlModalOpen = (
  fundingMethod: string,
  primaryCallback: NullaryFn<void>,
  secondaryCallback: NullaryFn<void>
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_FUNDING_CONTROL_OPEN",
    payload: {
      fundingMethod
    }
  };
};

export const rgTimeoutModalOpen = (
  period: number,
  endDate: string,
  primaryCallback: NullaryFn<void>,
  secondaryCallback: NullaryFn<void>
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_TIMEOUT_OPEN",
    payload: {
      period,
      endDate
    }
  };
};

export const rgSelfExclusionModalOpen = (
  period: number,
  endDate: string,
  primaryCallback: NullaryFn<void>,
  secondaryCallback: NullaryFn<void>
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_SELF_EXCLUSION_OPEN",
    payload: {
      period,
      endDate
    }
  };
};

export const rgSuspensionModalOpen = (
  primaryCallback: NullaryFn<void>,
  secondaryCallback: NullaryFn<void>
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_SUSPENSION_OPEN"
  };
};

export const rgSubmitModalOpen = (): RgModalActions =>
  mediator.base.dispatch({
    type: "RG_MODAL_SUBMIT"
  });

export const rgCancelModalOpen = (): RgModalActions =>
  mediator.base.dispatch({
    type: "RG_MODAL_CANCEL"
  });

export const rgSubmitModalError = (
  primaryCallback?: NullaryFn<void> | null,
  secondaryCallback?: NullaryFn<void> | null
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_ERROR"
  };
};

export const rgSubmitModalFinished = (
  primaryCallback: NullaryFn<void> | null,
  secondaryCallback: NullaryFn<void> | null
): RgModalActions => {
  clearCallbacks();
  if (primaryCallback) setCallback(primaryCallback);
  if (secondaryCallback) setCallbackSecondary(secondaryCallback);
  return {
    type: "RG_MODAL_FINISHED"
  };
};

export const rgCloseModal = (): RgModalActions => {
  clearCallbacks();

  return {
    type: "RG_MODAL_CLOSE"
  };
};

export const rgSetLoading = (isProcessing: boolean): RgModalActions => ({
  type: "RG_MODAL_PROCESSING",
  payload: {
    isProcessing
  }
});

export const rgModalClickGTM = (module: string, gaEventLabel: string): void => {
  mediator.base.dispatch({
    type: "RG_MODAL_CLICK_HANDLE",
    payload: {
      module,
      gaEventLabel
    }
  });
};
