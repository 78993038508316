// @flow
// $FlowFixMe
import { useEffect, useMemo, useState, useCallback } from "react";
import { get, capitalize } from "lodash";
import { addDays } from "date-fns";
import formatCurrency, {
  formatCurrencyToNumber
} from "@tvg/formatter/currency";
import { formatDateToMMDDYYYYSlashhhmm } from "@tvg/formatter/dates";
import mediator from "@tvg/mediator";
import type { Device } from "@tvg/types/Device";
import type {
  NullaryFn,
  UnaryFn,
  BinaryFn,
  QuinFn
} from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";
import RG_OPTIONS, {
  type Limits,
  type LimitsKeys
} from "@tvg/types/ResponsibleGaming";
import UWTService from "@tvg/api/uwt";

export const MODULE_NAME = RG_OPTIONS.DEPOSIT_LIMIT;

export const DAILY_PERIOD = "24 hours";
export const WEEKLY_PERIOD = "7 days";
export const MONTHLY_PERIOD = "30 days";

const DAILY_LIMIT = "dailyLimit";
const WEEKLY_LIMIT = "weeklyLimit";
const MONTHLY_LIMIT = "monthlyLimit";

const DEPOSIT_LIMITS_INDEX_MAPPING: LimitsKeys[] = [
  DAILY_LIMIT,
  WEEKLY_LIMIT,
  MONTHLY_LIMIT
];

const DAILY_PENDING_GAP = 1;
const WEEKLY_AND_MONTHLY_PENDING_GAP = 7;

const INSTANT_DATE = "Immediately";

const PERIOD_TOKEN = "#period";

type ModalMessages = {
  confirmTitle: string,
  successTitle: string,
  successMessage: string,
  errorTitle: string,
  errorMessage: string
};

export type Props = {
  device?: Device,
  isLogged: boolean,
  index: number,
  indexesApplied: number[],
  isActive: boolean,
  canSubmit: boolean,
  period: string,
  accountNumber: string,
  depositLimitsPage: {
    modalMessages: ?ModalMessages,
    depositLimitTitle: string,
    depositLimitTitleInfo: string,
    depositLimitSubTitleInfo: string,
    depositLimitFooterInfo: string,
    depositLimitPeriods?: ListItemType[],
    depositLimitSubmit?: {
      title: string,
      placeholder: string,
      buttonText: string
    },
    pendingLimitsConfig?: {
      currentLimitLabel: string,
      pendingLimitLabel: string,
      appliedLabel: string,
      editButtonText: string,
      cancelButtonText: string
    }
  },
  modalIsOpen: boolean,
  depositLimitModalOpen: QuinFn<
    string,
    string,
    string,
    NullaryFn<void>,
    NullaryFn<void>,
    void
  >,
  setIsLoading: UnaryFn<boolean, void>,
  setModalSuccess: UnaryFn<NullaryFn<void>, void>,
  setModalError: BinaryFn<NullaryFn<void>, NullaryFn<void>, void>,
  setModalClose: NullaryFn<void>,
  setIsComingFromLogin: UnaryFn<boolean, void>
};

export type DepositLimits = {
  dailyLimit: string,
  empty: true,
  monthlyLimit: string,
  pendingDailyLimit: string,
  pendingDailyLimitDate: string,
  pendingMonthlyLimit: string,
  pendingMonthlyLimitDate: string,
  pendingWeeklyLimit: string,
  pendingWeeklyLimitDate: string,
  remainingDepositLimit: string,
  weeklyLimit: string
};

export const MODAL_STATUS = {
  CLOSED: "",
  CONFIRM: "CONFIRM",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

const handleInput = (
  event: Event,
  setTargetAmount: UnaryFn<string, void>,
  setCanSubmit: UnaryFn<boolean, void>
) => {
  let targetValue = get(event, "target.value", "");
  targetValue = targetValue.replace(/\D/, "").slice(0, 6);
  const formattedCurrency = formatCurrency(targetValue, "USD", 0);
  const buffer = formattedCurrency.split("$")[1];
  if (buffer === "0") {
    setTargetAmount("0");
    setCanSubmit(false);
  } else {
    setTargetAmount(buffer);
    setCanSubmit(true);
  }
};

const onCleanInputHandler = (
  setTargetAmount: UnaryFn<string, void>,
  setCanSubmit: UnaryFn<boolean, void>
) => {
  setTargetAmount("0");
  setCanSubmit(false);
};

const updateIndex = (
  newIndex: number,
  index: number,
  setPeriod: UnaryFn<string, void>,
  setIndex: UnaryFn<number, void>,
  setTargetAmount: UnaryFn<string, void>,
  setIsActive: UnaryFn<boolean, void>,
  setCanSubmit: UnaryFn<boolean, void>,
  setIsComingFromLogin: UnaryFn<boolean, void>,
  isLogged: boolean,
  isFDR: boolean = false
) => {
  if (!isLogged) {
    mediator.base.dispatch({
      type: "OPEN_LOGIN",
      payload: {
        callback: (error, success) => {
          if (get(success, "status", "fail") === "success" || isFDR) {
            setIsComingFromLogin(true);
            updateIndex(
              newIndex,
              index,
              setPeriod,
              setIndex,
              setTargetAmount,
              setIsActive,
              setCanSubmit,
              setIsComingFromLogin,
              true,
              isFDR
            );
          }
        }
      }
    });

    return;
  }
  if (newIndex === index) {
    setIndex(0);
  } else {
    switch (newIndex) {
      default:
        setPeriod(DAILY_PERIOD);
        break;
      case 2:
        setPeriod(WEEKLY_PERIOD);
        break;
      case 3:
        setPeriod(MONTHLY_PERIOD);
        break;
    }
    setTargetAmount("0");
    setIsActive(false);
    setCanSubmit(false);
    setIndex(newIndex);
  }
};

const toggleActive = (
  isActive: boolean,
  setIsActive: UnaryFn<boolean, void>,
  setTargetAmount: UnaryFn<string, void>
) => {
  if (!isActive) {
    setIsActive(!isActive);
    setTargetAmount("0");
  }
};

const toggleNotActive = (
  isActive: boolean,
  targetAmount: string,
  setIsActive: UnaryFn<boolean, void>,
  setTargetAmount: UnaryFn<string, void>,
  setCanSubmit: UnaryFn<boolean, void>
) => {
  if ((isActive && targetAmount === "0") || !targetAmount) {
    setIsActive(!isActive);
    setTargetAmount("0");
    setCanSubmit(false);
  }
};

export const getIndexLimitApplied = (data: DepositLimits) => {
  const indexesApplied = [];
  if (data.dailyLimit)
    indexesApplied.push(DEPOSIT_LIMITS_INDEX_MAPPING.indexOf(DAILY_LIMIT) + 1);

  if (data.weeklyLimit)
    indexesApplied.push(DEPOSIT_LIMITS_INDEX_MAPPING.indexOf(WEEKLY_LIMIT) + 1);

  if (data.monthlyLimit)
    indexesApplied.push(
      DEPOSIT_LIMITS_INDEX_MAPPING.indexOf(MONTHLY_LIMIT) + 1
    );

  return indexesApplied;
};

export const fetchLimits = (
  accountNumber: string,
  setLimits: UnaryFn<DepositLimits, void>,
  setShouldUpdateLimits: UnaryFn<boolean, void>,
  setIsLoading: UnaryFn<boolean, void>,
  setIndexesApplied: UnaryFn<number[], void>,
  setHasError: UnaryFn<boolean, void>
) => {
  if (accountNumber) {
    return UWTService.getDepositLimits(accountNumber)
      .then(({ data }: { data: DepositLimits }) => {
        setLimits(data);
        setIndexesApplied(getIndexLimitApplied(data));
        setShouldUpdateLimits(false);
        setIsLoading(false);
      })
      .catch(() => {
        setShouldUpdateLimits(false);
        setIsLoading(false);
        setHasError(true);
      });
  }

  const initialData = {
    empty: true
  };

  // $FlowFixMe
  setLimits(initialData);
  // $FlowFixMe
  setIndexesApplied(getIndexLimitApplied(initialData));
  setShouldUpdateLimits(false);
  setIsLoading(false);

  return Promise.resolve({});
};

export const parseNewLimits = (
  limits: Limits,
  limitKey: LimitsKeys,
  limitValue: number
) => {
  const parsedLimits = {};
  parsedLimits[limitKey] = limitValue;
  return parsedLimits;
};

export const parseLimitInput = (targetAmount: string) =>
  +targetAmount.replace(/,/g, "");

export const parseLimitKey = (inputString: string) =>
  capitalize(inputString).replace("limit", "Limit");

export const emitApplyLimitGtmClick = (
  targetAmount: string,
  period: string
): void => {
  mediator.base.dispatch({
    type: "RG_DEPOSIT_LIMIT_CLICK",
    payload: {
      gaEventLabel: `$${targetAmount} - ${period}`
    }
  });
};

export const modalClickGtmHandle = (gaEventLabel: string) => {
  mediator.base.dispatch({
    type: "RG_MODAL_CLICK_HANDLE",
    payload: {
      gaEventLabel,
      module: MODULE_NAME
    }
  });
};

/*
  Deposit Limits custom hook function
*/
export const useDepositLimits = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isComingFromLogin, setIsComingFromLogin] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexesApplied, setIndexesApplied] = useState([]);
  const [period, setPeriod] = useState(DAILY_PERIOD);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [targetAmount, setTargetAmount] = useState("0");
  const [modalType, setModalType] = useState(MODAL_STATUS.CLOSED);
  const [shouldUpdateLimits, setShouldUpdateLimits] = useState(true);
  const [limits, setLimits] = useState(null);
  const [isInputActive, setIsInputActive] = useState(false);
  const limitsKeyWorker = useMemo(
    () => get(DEPOSIT_LIMITS_INDEX_MAPPING, `[${index - 1}]`),
    [index]
  );
  const depositLimitFooterInfo = get(
    props,
    "depositLimitsPage.depositLimitFooterInfo",
    ""
  );

  const setIsInputActiveWrapper = (value: boolean) => {
    setTargetAmount("0");
    setCanSubmit(false);
    setIsInputActive(value);
  };
  const limitsInfo = useMemo(() => {
    if (index && limits) {
      const previousLimit = limits[limitsKeyWorker];
      const pendingLimit = limits[`pending${parseLimitKey(limitsKeyWorker)}`];
      const pendingLimitDate =
        limits[`pending${parseLimitKey(limitsKeyWorker)}Date`];

      return {
        previousLimit: previousLimit
          ? formatCurrency(previousLimit, "USD", 0)
          : null,
        pendingLimit: pendingLimit
          ? formatCurrency(pendingLimit, "USD", 0)
          : null,
        pendingLimitDate: pendingLimitDate
          ? formatDateToMMDDYYYYSlashhhmm(pendingLimitDate)
          : null,
        newLimit: targetAmount
          ? `$${targetAmount}`
          : formatCurrency(pendingLimit, "USD", 0),
        applyDate:
          !previousLimit || previousLimit > formatCurrencyToNumber(targetAmount)
            ? INSTANT_DATE
            : formatDateToMMDDYYYYSlashhhmm(
                addDays(
                  new Date(),
                  DEPOSIT_LIMITS_INDEX_MAPPING[index - 1] === DAILY_LIMIT
                    ? DAILY_PENDING_GAP
                    : WEEKLY_AND_MONTHLY_PENDING_GAP
                )
              )
      };
    }
    return null;
  }, [JSON.stringify(limits), index, targetAmount]);
  const showMessages = useMemo(() => {
    if (modalType !== MODAL_STATUS.CONFIRM) return true;

    let hasPreviousLimit = false;
    if (index && limits) hasPreviousLimit = !!limits[limitsKeyWorker];
    return hasPreviousLimit;
  }, [(JSON.stringify(limits), index, targetAmount), modalType]);
  const onSubmitCallback = useCallback(() => {
    setModalType(MODAL_STATUS.CONFIRM);
    emitApplyLimitGtmClick(targetAmount, period);
  }, [targetAmount, period]);
  const onCancelCallback = (eventLabel: string) => {
    setModalType(MODAL_STATUS.CLOSED);
    modalClickGtmHandle(eventLabel);
  };
  const updateDepositLimits = useCallback(() => {
    setIsLoading(true);
    modalClickGtmHandle("confirm");
    return UWTService.updateDepositLimits(
      props.accountNumber,
      parseNewLimits(
        limits,
        DEPOSIT_LIMITS_INDEX_MAPPING[index - 1],
        parseLimitInput(targetAmount)
      )
    )
      .then(() => {
        setModalType(MODAL_STATUS.SUCCESS);
        setShouldUpdateLimits(true);
      })
      .catch(() => {
        setModalType(MODAL_STATUS.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [JSON.stringify(limits), props.accountNumber, index, targetAmount]);
  const modalConfigs = useMemo(
    () => ({
      CONFIRM: {
        title: get(props, "depositLimitsPage.modalMessages.confirmTitle"),
        messages: get(
          props,
          "depositLimitsPage.modalMessages.confirmMessage",
          ""
        )
          .replace(PERIOD_TOKEN, period)
          .split("/n"),
        hasSeparator: true,
        limitsDisplay: "CONFIRM",
        primaryButton: {
          text: "Confirm limit",
          type: "betting",
          callback: updateDepositLimits
        },
        secondaryButton: {
          text: "Cancel",
          callback: () => onCancelCallback("cancel")
        }
      },
      SUCCESS: {
        title: get(props, "depositLimitsPage.modalMessages.successTitle"),
        messages: get(
          props,
          "depositLimitsPage.modalMessages.successMessage",
          ""
        )
          .replace(PERIOD_TOKEN, period)
          .split("/n"),
        limitsDisplay: "SUCCESS",
        hasSeparator: true,
        primaryButton: {
          text: "Return to homepage",
          type: "primary",
          url: "/"
        },
        secondaryButton: {
          text: "Go back to deposit limits",
          url: "/responsible-gaming/deposit-limits",
          callback: () => onCancelCallback("back")
        }
      },
      ERROR: {
        title: get(props, "depositLimitsPage.modalMessages.errorTitle"),
        messages: get(
          props,
          "depositLimitsPage.modalMessages.errorMessage",
          ""
        ).split("/n"),
        primaryButton: {
          text: "Contact Support",
          url: "/redirectEngine?type=messageus",
          callback: () => modalClickGtmHandle("open support")
        },
        secondaryButton: {
          text: "Go back to deposit limits",
          callback: () => onCancelCallback("back")
        }
      }
    }),
    [JSON.stringify(props.depositLimitsPage), updateDepositLimits, period]
  );
  const capiMessagesInstantiated = useMemo(
    () => ({
      depositLimitFooterInfo: depositLimitFooterInfo.replace(
        PERIOD_TOKEN,
        period
      )
    }),
    [depositLimitFooterInfo, period]
  );
  useEffect(() => {
    if (props.accountNumber) {
      setShouldUpdateLimits(true);
    }
  }, [props.accountNumber]);
  useEffect(() => {
    if (shouldUpdateLimits || isComingFromLogin) {
      if (isComingFromLogin && props.accountNumber) {
        setIsComingFromLogin(false);
      }
      fetchLimits(
        props.accountNumber,
        setLimits,
        setShouldUpdateLimits,
        setIsLoading,
        setIndexesApplied,
        setHasError
      );
    }
  }, [shouldUpdateLimits, props.accountNumber, isComingFromLogin]);
  return {
    index,
    indexesApplied,
    period,
    isActive,
    isLoading,
    canSubmit,
    targetAmount,
    modalType,
    setModalType,
    limits,
    modalConfigs,
    onCancelCallback,
    onSubmitCallback,
    isInputActive,
    setIsInputActive: setIsInputActiveWrapper,
    setTargetAmount,
    setIsActive,
    setCanSubmit,
    setPeriod,
    setIndex,
    limitsInfo,
    showMessages,
    hasError,
    capiMessagesInstantiated,
    setIsComingFromLogin
  };
};

export default {
  handleInput,
  onCleanInputHandler,
  updateIndex,
  toggleActive,
  toggleNotActive,
  useDepositLimits
};
