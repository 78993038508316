// @flow
import { addDays } from "date-fns";
import { get } from "lodash";
import uamService from "@tvg/api/uam";
import tvgConf from "@tvg/conf";
import { formatDateToMMDDYYYYhhmm } from "@tvg/formatter/dates";
import LoginService from "@tvg/login-service";
import type { LogoutResponse } from "@tvg/login-service/src/types";
import mediator from "@tvg/mediator";
import type { NullaryFn, UnaryFn, BinaryFn } from "@tvg/types/Functional";
import type { ListItemType } from "@tvg/types/ListItem";
import RG_OPTIONS, {
  RG_TIMEOUT_EVENTS_MAPPING
} from "@tvg/types/ResponsibleGaming";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";

export const MODULE_NAME = RG_OPTIONS.TIMEOUT;

const NATIVE_PRODUCTS = ["iosnative", "androidnative"];

export const MODAL_STATUS = {
  CLOSED: "",
  CONFIRM: "CONFIRM",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export type RGTimeoutExclusionType = "TIMEOUT" | "SELFEXCLUSION" | "SUSPEND";

const { TIMEOUT } = RG_OPTIONS;
const { SELF_EXCLUSION } = RG_OPTIONS;
const { SUSPEND } = RG_OPTIONS;

const EXCLUSION_TYPES = [TIMEOUT, SELF_EXCLUSION, SUSPEND];

const SELF_EXCLUDE_YEARS = [1, 3, 5];

const TIMEOUT_MIN_DAYS = 3;
const YEAR_DAYS = 365;

const getExclusionType = (optionIdx: number) =>
  optionIdx < EXCLUSION_TYPES.length ? EXCLUSION_TYPES[optionIdx] : TIMEOUT;

const getSelfExcludeYears = (selfExcludeIdx: number) =>
  selfExcludeIdx < SELF_EXCLUDE_YEARS.length
    ? SELF_EXCLUDE_YEARS[selfExcludeIdx]
    : 1;

const calcPeriodInDays = (
  exclusionType: string,
  selfExcludeYears: ?number,
  timeout: ?number
) => {
  switch (exclusionType) {
    case TIMEOUT:
      return timeout;
    case SELF_EXCLUSION:
      return (selfExcludeYears || SELF_EXCLUDE_YEARS[0]) * YEAR_DAYS;
    default:
      return null;
  }
};

export const emitTimeoutGtmClick = (
  options: ListItemType[],
  optionIdx: number,
  timeoutInputValue: string,
  selfExcludeIdx: number
): void => {
  let gaEventLabel;
  switch (getExclusionType(optionIdx)) {
    case TIMEOUT:
      gaEventLabel = `${options[optionIdx].id} - ${timeoutInputValue} days`;
      break;
    case SELF_EXCLUSION:
      gaEventLabel = `${options[optionIdx].id} - ${getSelfExcludeYears(
        selfExcludeIdx
      )} ${selfExcludeIdx === 0 ? "year" : "years"}`;
      break;
    default:
      gaEventLabel = "suspension";
  }

  mediator.base.dispatch({
    type: "RG_TIMEOUT_CLICK",
    payload: {
      gaEventLabel
    }
  });
};

export const modalClickGtmHandle = (gaEventLabel: string) => {
  mediator.base.dispatch({
    type: "RG_MODAL_CLICK_HANDLE",
    payload: {
      gaEventLabel,
      module: MODULE_NAME
    }
  });
};

const validateTimout = (value: string): boolean => {
  if (isNaN(value)) return false;

  const newTimeout = parseFloat(value);
  return (
    Number.isInteger(newTimeout) &&
    newTimeout >= TIMEOUT_MIN_DAYS &&
    newTimeout <= YEAR_DAYS
  );
};

const getEndDateFormated = (days: number) => {
  const today = new Date();
  const newDate = addDays(today, days);

  return formatDateToMMDDYYYYhhmm(newDate);
};

const formatMessages = (
  messages: string[],
  exclusionType: string,
  selfExcludeYears: ?number,
  timeout: ?number
): string[] => {
  if (exclusionType === SUSPEND) return messages;

  const regexNumDaysTag = /#EXCLUSION_DAYS/gi;
  const regexNumYearsTag = /#EXCLUSION_YEARS/gi;
  const regexEndDateTag = /#EXCLUSION_END_DATE/gi;

  const exclusionInDays =
    calcPeriodInDays(exclusionType, selfExcludeYears, timeout) ||
    TIMEOUT_MIN_DAYS;
  const exclusionEndDate = getEndDateFormated(exclusionInDays);
  return messages.map((msg: string) =>
    msg
      .replace(regexNumDaysTag, `<span>${exclusionInDays}</span>`)
      .replace(
        regexNumYearsTag,
        `<span>${selfExcludeYears || SELF_EXCLUDE_YEARS[0]}</span>`
      )
      .replace(regexEndDateTag, `<span>${exclusionEndDate}</span>`)
  );
};

const verifyIcons = (
  lisItems: any[],
  listItemsDefault: any[],
  iconExceptions: string[] = [],
  iconFallback: string = "exclamation"
): any[] => {
  if (!lisItems) return lisItems;

  return lisItems.map((item, idx) => {
    if (!item.icon) return item;
    if (iconExceptions.includes(item.icon) || !get(allIcons, `${item.icon}`)) {
      const newItem = { ...item };
      newItem.icon = get(listItemsDefault, `${idx}.icon`, iconFallback);
      return newItem;
    }
    return item;
  });
};

const selfExclusion = (
  accountNumber: number,
  exclusionType: string,
  periodInDays: ?number
) => uamService.selfExclusion(accountNumber, exclusionType, periodInDays);

const applyTimeoutExclusion = (
  accountNumber: number,
  exclusionType: string,
  periodInDays: ?number,
  setIsLoading: UnaryFn<boolean, void>,
  setCloseModal: NullaryFn<void>,
  setModalError: NullaryFn<void>,
  showTimeoutExclusionSplash: BinaryFn<string, string, void>,
  isDesktop: boolean = false
) => {
  setIsLoading(true);
  modalClickGtmHandle(`confirm ${exclusionType.toLowerCase()}`);
  return uamService
    .selfExclusion(accountNumber, exclusionType, periodInDays)
    .then(() => {
      if (isDesktop) {
        setCloseModal();
        mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
        return;
      }
      setCloseModal();
      LoginService.doLogout().then((response: LogoutResponse) => {
        if (get(response, "success.status") === "success") {
          if (NATIVE_PRODUCTS.includes(tvgConf().product)) {
            mediator.base.dispatch({
              type: RG_TIMEOUT_EVENTS_MAPPING[exclusionType],
              payload: periodInDays ? getEndDateFormated(periodInDays) : ""
            });
            mediator.base.dispatch({ type: "LEAVE_RG" });
          } else {
            showTimeoutExclusionSplash(
              exclusionType,
              exclusionType !== SUSPEND && periodInDays
                ? getEndDateFormated(periodInDays)
                : ""
            );
          }
        }
      });
    })
    .catch(() => {
      setModalError();
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export default {
  TIMEOUT,
  SELF_EXCLUSION,
  SUSPEND,
  MODAL_STATUS,
  EXCLUSION_TYPES,
  SELF_EXCLUDE_YEARS,
  YEAR_DAYS,
  selfExclusion,
  applyTimeoutExclusion,
  getExclusionType,
  getSelfExcludeYears,
  validateTimout,
  calcPeriodInDays,
  formatMessages,
  getEndDateFormated,
  verifyIcons
};
