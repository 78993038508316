// @flow
import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontBold, defaultSize } from "../../_static/Typography";

export default styled.ul`
  background-color: ${buildColor("white", "100")};
  border: 1px solid rgb(214, 227, 240);
  width: 100%;
  list-style-type: none;
  padding: 12px;
  display: flex;
  flex-direction: column;

  & > li:not(:last-child) {
    border-bottom: none;
  }

  & > li:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  & > li:last-of-type {
    border-radius: 0 0 4px 4px;
  }
`;

export const ListItemsTitle = styled.h1`
  margin: 4px 0;
  color: ${buildColor("grey", "900")};
  font-size: 16px;
  font-family: ${fontBold};
  letter-spacing: 0;
`;

export const ListItemsDescription = styled.span`
  color: ${buildColor("grey", "800")};
  margin-bottom: 12px;
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
`;
