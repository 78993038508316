// @flow
import styled from "styled-components";
import { fontNormal, fontMedium, defaultSize } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const PendingLimitsSeparator = styled.div`
  margin: 16px -16px 0 0;
  height: 1px;
  background-color: ${buildColor("blue", "100")};
`;

export const Container = styled.section`
  padding: 18px 0 2px;
  display: flex;
  justify-content: space-between;

  & .editLimitBtn {
    color: ${buildColor("grey", "900")};
    font-size: ${defaultSize};
    font-family: ${fontMedium};
    font-weight: 500;
    letter-spacing: 0;
    text-transform: capitalize;
  }
`;

export const CurrentPendingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 520px;
`;

export const PendingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
`;

export const LimitDescription = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${({ isCurrentType }) =>
    isCurrentType ? buildColor("grey", "100") : buildColor("orange", "100")};
`;

export const IconWrapperSlim = styled.div`
  flex-basis: 40px;
  align-self: center;
  width: 18px;
  height: 18px;
`;

export const LimitsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 16px;
`;

export const LimitValue = styled.span`
  color: ${buildColor("grey", "900")};
  height: 16px;
  font-size: ${defaultSize};
  font-family: ${fontMedium};
  font-weight: 500;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  align-self: ${(props) => (props.rightAlignment ? "flex-end" : "flex-start")};
`;

export const LimitStatus = styled.span`
  height: 16px;
  color: ${buildColor("grey", "800")};
  font-size: ${defaultSize};
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  align-self: ${(props) => (props.rightAlignment ? "flex-end" : "flex-start")};
`;

export const LimitSeparator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 12px;
`;

export const LimitSeparatorLine = styled.span`
  flex-grow: 1;
  height: 1px;
  background-color: ${buildColor("blue", "100")};
  margin-left: 16px;
  margin-right: -12px;
`;

export const PendingLimitDateDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
