// @flow
import React, { PureComponent, type Node } from "react";
import { noop } from "lodash";
import type { ListItemType } from "@tvg/types/ListItem";
import type { BinaryFn } from "@tvg/types/Functional";
import ListItem from "../../_molecule/ListItem";

import ListItemsWrapper, {
  ListItemsTitle,
  ListItemsDescription
} from "./styled-components";

type Props = {
  title: string,
  description?: string,
  listItems: ListItemType[],
  buttonClickHandler: BinaryFn<string, ?string, void>
};

export default class ListItems extends PureComponent<Props> {
  static defaultProps = {
    title: "",
    description: null,
    listItems: [],
    buttonClickHandler: noop
  };

  renderList = (
    listItems: ListItemType[],
    buttonClickHandler: BinaryFn<string, ?string, void>
  ): Node =>
    listItems.map((item: ListItemType) => (
      <ListItem
        item={{
          ...item,
          promptClick: () => buttonClickHandler(item.title, item.url)
        }}
        key={`listItems_${item.id}`}
      />
    ));

  render = () => {
    const { title, description, listItems, buttonClickHandler } = this.props;
    return (
      <ListItemsWrapper data-qa-label={`${title.replace(/ /g, "")}_ListItems`}>
        <ListItemsTitle data-qa-label={title.replace(/ /g, "")}>
          {title}
        </ListItemsTitle>
        {description && (
          <ListItemsDescription
            data-qa-label={`${title.replace(/ /g, "")}_Description`}
          >
            {description}
          </ListItemsDescription>
        )}

        {this.renderList(listItems, buttonClickHandler)}
      </ListItemsWrapper>
    );
  };
}
