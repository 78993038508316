// @flow
// $FlowFixMe
import React, { useMemo, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { get } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import DepositLimitsLib, {
  type Props,
  MODAL_STATUS
} from "@tvg/sh-lib-deposit-limits";
import DepositLimitsTemplate from "@tvg/atomic-ui/_templates/DepositLimits";
import parseCapiMessage from "@tvg/utils/capiUtils";
import {
  rgDepositLimitModalOpen,
  rgSetLoading,
  rgSubmitModalFinished,
  rgCloseModal
} from "@tvg/responsible-gaming-modal/src/actions";
import { getAccountNumber } from "@urp/store-selectors";

export const handleInput = (
  event: Event,
  setTargetAmount: UnaryFn<string, void>,
  setCanSubmit: UnaryFn<boolean, void>
) => DepositLimitsLib.handleInput(event, setTargetAmount, setCanSubmit);

export const updateIndex = (
  newIndex: number,
  index: number,
  setPeriod: UnaryFn<string, void>,
  setIndex: UnaryFn<number, void>,
  setTargetAmount: UnaryFn<string, void>,
  setIsActive: UnaryFn<boolean, void>,
  setCanSubmit: UnaryFn<boolean, void>,
  setIsComingFromLogin: UnaryFn<boolean, void>,
  isLogged: boolean
) => {
  DepositLimitsLib.updateIndex(
    newIndex,
    index,
    setPeriod,
    setIndex,
    setTargetAmount,
    setIsActive,
    setCanSubmit,
    setIsComingFromLogin,
    isLogged
  );
};

export const toggleActive = (
  isActive: boolean,
  setIsActive: UnaryFn<boolean, void>,
  setTargetAmount: UnaryFn<string, void>
) => DepositLimitsLib.toggleActive(isActive, setIsActive, setTargetAmount);

export const toggleNotActive = (
  isActive: boolean,
  targetAmount: string,
  setIsActive: UnaryFn<boolean, void>,
  setTargetAmount: UnaryFn<string, void>,
  setCanSubmit: UnaryFn<boolean, void>
) =>
  DepositLimitsLib.toggleNotActive(
    isActive,
    targetAmount,
    setIsActive,
    setTargetAmount,
    setCanSubmit
  );

export const onErrorButtonCallback = () => window.location.reload();

export const DepositLimitsPage = (props: Props) => {
  const DL = DepositLimitsLib.useDepositLimits(props);

  useEffect(() => {
    props.setIsLoading(DL.isLoading);
  }, [DL.isLoading]);
  useEffect(() => {
    switch (DL.modalType) {
      case MODAL_STATUS.CONFIRM:
        props.depositLimitModalOpen(
          DL.limitsInfo.newLimit,
          DL.limitsInfo.applyDate,
          DL.limitsInfo.previousLimit,
          DL.modalConfigs.CONFIRM.primaryButton.callback,
          DL.modalConfigs.CONFIRM.secondaryButton.callback
        );
        break;
      case MODAL_STATUS.SUCCESS:
        props.setModalSuccess(DL.modalConfigs.SUCCESS.secondaryButton.callback);
        break;
      case MODAL_STATUS.ERROR:
        break;
      default:
        // MODAL_STATUS.CLOSED
        props.setModalClose();
    }
  }, [DL.modalType]);
  useEffect(() => {
    if (!props.modalIsOpen && DL.modalType !== MODAL_STATUS.CLOSED)
      DL.setModalType(MODAL_STATUS.CLOSED);
  }, [props.modalIsOpen]);

  const {
    modalMessages,
    depositLimitTitle,
    depositLimitTitleInfo,
    depositLimitSubTitleInfo,
    depositLimitPeriods,
    depositLimitSubmit,
    pendingLimitsConfig
  } = props.depositLimitsPage;

  return useMemo(
    () => (
      <Fragment>
        <DepositLimitsTemplate
          device={props.device}
          hasError={DL.hasError}
          capiMessages={modalMessages}
          onErrorButtonCallback={onErrorButtonCallback}
          isLoading={DL.isLoading}
          index={DL.index}
          indexesApplied={DL.indexesApplied}
          updateIndex={(newIndex) =>
            updateIndex(
              newIndex,
              DL.index,
              DL.setPeriod,
              DL.setIndex,
              DL.setTargetAmount,
              DL.setIsActive,
              DL.setCanSubmit,
              DL.setIsComingFromLogin,
              props.isLogged
            )
          }
          isInputActive={DL.isInputActive}
          toggleInputCallback={DL.setIsInputActive}
          limits={DL.limitsInfo}
          onSubmitClick={DL.onSubmitCallback}
          isActive={DL.isActive}
          canSubmit={DL.canSubmit}
          depositLimitTitle={depositLimitTitle}
          depositLimitTitleInfo={depositLimitTitleInfo}
          depositLimitSubTitleInfo={depositLimitSubTitleInfo}
          depositLimitFooterInfo={
            DL.capiMessagesInstantiated.depositLimitFooterInfo
          }
          depositLimitPeriods={depositLimitPeriods}
          depositLimitSubmit={depositLimitSubmit}
          pendingLimitsConfig={pendingLimitsConfig}
          targetAmount={DL.targetAmount}
          handleInput={(event) =>
            handleInput(event, DL.setTargetAmount, DL.setCanSubmit)
          }
          toggleActive={() =>
            toggleActive(DL.isActive, DL.setIsActive, DL.setTargetAmount)
          }
          toggleNotActive={() =>
            toggleNotActive(
              DL.isActive,
              DL.targetAmount,
              DL.setIsActive,
              DL.setTargetAmount,
              DL.setCanSubmit
            )
          }
          onCleanInputHandler={() =>
            DepositLimitsLib.onCleanInputHandler(
              DL.setTargetAmount,
              DL.setCanSubmit
            )
          }
        />
      </Fragment>
    ),
    [
      DL.index,
      DL.isActive,
      DL.canSubmit,
      DL.targetAmount,
      DL.isInputActive,
      DL.modalType,
      JSON.stringify(DL.limits),
      DL.isLoading,
      DL.hasError,
      props.depositLimitsPage,
      DL.indexesApplied,
      props.isLogged,
      props.accountNumber
    ]
  );
};

DepositLimitsPage.defaultProps = {
  device: "mobile",
  index: 0,
  indexesApplied: [],
  isActive: false,
  canSubmit: false,
  period: "24 hour",
  depositLimitsPage: {
    modalMessages: null,
    depositLimitTitle: "",
    depositLimitTitleInfo: "",
    depositLimitSubTitleInfo: "",
    depositLimitFooterInfo: "",
    depositLimitPeriods: [],
    depositLimitSubmit: {
      title: "",
      placeholder: "",
      buttonText: ""
    },
    pendingLimitsConfig: {
      currentLimitLabel: "",
      pendingLimitLabel: "",
      appliedLabel: "",
      editButtonText: "",
      cancelButtonText: ""
    }
  }
};

export const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  depositLimitModalOpen: (
    newLimit: string,
    applyDate: string,
    previousLimit: string,
    primaryButtonCallback: NullaryFn<void>,
    secondaryButtonCallback: NullaryFn<void>
  ) =>
    dispatch(
      rgDepositLimitModalOpen(
        newLimit,
        applyDate,
        previousLimit,
        primaryButtonCallback,
        secondaryButtonCallback
      )
    ),
  setIsLoading: (isLoading: boolean) => dispatch(rgSetLoading(isLoading)),
  setModalSuccess: (secondaryButtonCallback: NullaryFn<void>) =>
    dispatch(rgSubmitModalFinished(null, secondaryButtonCallback)),
  setModalClose: () => dispatch(rgCloseModal())
});

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged", false),
    depositLimitsPage: parseCapiMessage(
      store,
      "capi.messages.rgDepositLimitsPage",
      {
        modalMessages: null,
        depositLimitTitle: "",
        depositLimitTitleInfo: "",
        depositLimitSubTitleInfo: "",
        depositLimitFooterInfo: "",
        depositLimitPeriods: [],
        depositLimitSubmit: {
          title: "",
          placeholder: "",
          buttonText: ""
        },
        pendingLimitsConfig: {
          currentLimitLabel: "",
          pendingLimitLabel: "",
          appliedLabel: "",
          editButtonText: "",
          cancelButtonText: ""
        }
      }
    ),
    accountNumber: getAccountNumber(store),
    modalIsOpen: get(store, "modal.responsibleGaming.isOpen", false)
  }),
  mapDispatchToProps
)(DepositLimitsPage);
