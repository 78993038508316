// @flow
export default {
  register: (mark: string, timing: number | null = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.register(mark, timing);
    }
  },
  measure: (mark: string, tags: { [string]: string } | null = null) => {
    if (typeof window === "object" && window.perf) {
      window.perf.measure(mark, tags);
    }
  }
};
