// @flow
import type { Dispatch } from "redux";
import type { Device } from "@tvg/types/Device";
import type { NullaryFn } from "@tvg/types/Functional";

// eslint-disable-next-line import/prefer-default-export
export const MODAL_TITLE_TYPE = {
  SUCCESS: "success",
  ERROR: "warning",
  DEFAULT: "subtitle"
};

export type Status = "success" | "error" | "confirmation";

export type RGDModalMessagesTitle = {
  success?: string,
  error: string,
  confirmation: string
};

export type RGModalMessagesValues = {
  limit: string,
  applied: string
};

export type RGModalMessagesButtons = {
  confirmation: string,
  cancel: string,
  back: string,
  contactUs: string,
  homepage?: string
};

export type RGModalContent = {
  title: RGDModalMessagesTitle,
  values: RGModalMessagesValues,
  buttons: RGModalMessagesButtons,
  error: string
};

export type RGModalExclusionContent = {
  timeout: RGModalContent,
  selfExclusion: RGModalContent,
  suspension: RGModalContent
};

export type RGDepositLimitsModalMessages = {
  depositLimit: RGModalContent,
  fundingControl: RGModalContent,
  exclusion: RGModalExclusionContent
};

export type ModalProps = {
  device: Device,
  type: string,
  messages?: string[],
  status: Status,
  titleType: string,
  closeModal: NullaryFn<void>,
  dailyLimit: string,
  oldDailyLimit: string | null,
  appliedDate: string,
  fundingMethod: string,
  timeoutPeriod: string,
  returnDate: string,
  submit: NullaryFn<void>,
  cancel: NullaryFn<void>,
  customerServicesUrl: string,
  isProcessing: boolean
};

export type Props = {
  isOpen: boolean,
  dispatch: Dispatch<*>,
  type: string,
  isProcessing: boolean,
  closeModal: NullaryFn<void>,
  rgModalMessages: RGDepositLimitsModalMessages,
  device: Device,
  status: Status,
  dailyLimit: string,
  oldDailyLimit: string | null,
  appliedDate: string,
  fundingMethod: string,
  timeoutPeriod: string,
  returnDate: string,
  submit: NullaryFn<void>,
  cancel: NullaryFn<void>,
  customerServicesUrl: string
};
