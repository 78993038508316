import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { titleFont } from "@tvg/atomic-ui/_static/Typography";

export const HeaderContainer = styled.header`
  width: 100%;
  height: 48px;
  padding: 8px;
  background-color: ${(props) =>
    props.isRoot ? buildColor("blue", "700") : buildColor("white", "100")};
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Title = styled.span`
  font-family: ${titleFont};
  padding-left: 12px;
  color: ${(props) =>
    props.isRoot ? buildColor("white", "100") : buildColor("grey", "900")};
  text-transform: uppercase;
  font-size: 16px;
`;
