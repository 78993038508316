// @flow
import React from "react";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import * as allIcons from "../../_static/Icons/icons";
import * as duotoneIcons from "../../_static/Icons/iconsDuotone";
import { IconContainer, DescriptionText } from "./styled-components";

type Props = {
  icon: string,
  description: string,
  size?: number,
  color?: string,
  stroke?: string,
  variation?: string,
  strokeVariation?: string,
  isDuotone?: boolean,
  viewBoxSize?: number,
  textColor?: string,
  textColorVariation?: string,
  iconMargin?: number,
  id: string,
  fontSize?: number
};

const IconDescription = ({
  icon,
  description,
  size,
  color,
  stroke,
  variation,
  strokeVariation,
  isDuotone,
  viewBoxSize,
  textColor,
  textColorVariation,
  iconMargin,
  id,
  fontSize
}: Props) => (
  <IconContainer iconMargin={`${iconMargin || "10"}px`} fontSize={fontSize}>
    <Icon
      qaLabel={`icon_${id}`}
      icon={isDuotone ? duotoneIcons[icon] : allIcons[icon]}
      color={buildColor(color || "grey", variation || "800")}
      stroke={
        stroke && strokeVariation ? buildColor(stroke, strokeVariation) : null
      }
      size={size}
      viewBoxSize={viewBoxSize}
    />
    <DescriptionText
      color={buildColor(textColor || "grey", textColorVariation || "800")}
      data-qa-label={`description_${id}`}
    >
      {description}
    </DescriptionText>
  </IconContainer>
);

IconDescription.defaultProps = {
  icon: "lock",
  description: "",
  size: 16,
  color: "grey",
  stroke: "grey",
  variation: "800",
  strokeVariation: "800",
  isDuotone: false,
  textColor: "grey",
  textColorVariation: "800",
  iconMargin: 10,
  viewBoxSize: 1024,
  fontSize: 14
};

export default IconDescription;
