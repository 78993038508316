// @flow
// $FlowFixMe
import React, { Fragment } from "react";
import { noop } from "lodash";
import type { UnaryFn, NullaryFn } from "@tvg/types/Functional";
import {
  InputWrapper,
  UnderlineInfo,
  TitleContainer
} from "./styled-components";
import LimitsInput from "../GenericInput";
import Button from "../../_atom/Buttons/button";

type Props = {
  title: string,
  placeholder: string,
  submitButtonText: string,
  isValid: boolean,
  canSubmit: boolean,
  isVisible: boolean,
  footerInfo: string,
  handleInput: UnaryFn<Event, void>,
  targetAmount: string,
  toggleActive: NullaryFn<void>,
  toggleNotActive: NullaryFn<void>,
  onCleanInputHandler: NullaryFn<void>,
  onSubmitClick: NullaryFn<void>,
  qaLabel: string
};

const SubmitLimits = ({
  title,
  placeholder,
  submitButtonText,
  isValid,
  canSubmit,
  isVisible,
  footerInfo,
  handleInput,
  targetAmount,
  toggleActive,
  toggleNotActive,
  onCleanInputHandler,
  onSubmitClick,
  qaLabel
}: Props) =>
  isVisible && (
    <Fragment>
      <TitleContainer data-qa-label={`title${title.replace(/ /g, "")}`}>
        {title}
      </TitleContainer>
      <InputWrapper data-qa-label={`${qaLabel}InputWrapper`}>
        <LimitsInput
          type="text"
          pattern="[0-9]*"
          value={targetAmount}
          isValid={isValid}
          placeholder={placeholder}
          maxLength={7}
          opacityPlaceholder
          onChangeHandler={handleInput}
          onFocusHandler={toggleActive}
          onBlurHandler={toggleNotActive}
          onKeyPressHandler={(e) =>
            (e.key === "e" || e.key === ".") && e.preventDefault()
          }
          onCleanInputHandler={onCleanInputHandler}
          qaLabel="inputAmount"
        />
      </InputWrapper>
      <Button
        qaLabel="applyLimitButton"
        type="primary"
        size="big"
        isStretched
        isDisabled={!canSubmit}
        onClick={onSubmitClick}
        optionalData="openRGModal"
      >
        {submitButtonText}
      </Button>
      <UnderlineInfo data-qa-label="underlineInfo">{footerInfo}</UnderlineInfo>
    </Fragment>
  );

SubmitLimits.defaultProps = {
  title: "",
  placeholder: "0.00",
  submitButtonText: "",
  isValid: true,
  canSubmit: false,
  isVisible: true,
  footerInfo: "",
  handleInput: noop,
  targetAmount: "0",
  toggleActive: noop,
  toggleNotActive: noop,
  onCleanInputHandler: noop,
  onSubmitClick: noop,
  qaLabel: "DLSubmitLimits"
};

export default SubmitLimits;
